import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Modal,
  Divider,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Chip,
  useMediaQuery,
  DialogActions,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip,
  InputAdornment,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  RadioGroup,
  Radio,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Drawer,
  Alert,
  Autocomplete,
  Badge,
  Popper,
} from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import {
  Add,
  ArrowBack,
  Article,
  Assistant,
  AudioFile,
  Audiotrack,
  AutoAwesome,
  BookmarkAdd,
  Cached,
  Chat,
  Check,
  ChevronRight,
  Close,
  CloudUpload,
  Delete,
  ErrorOutline,
  FilterList,
  FindReplace,
  Info,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Lock,
  Loop,
  Mic,
  PlayArrow,
  Redo,
  Refresh,
  Replay,
  Search,
  Send,
  StopCircle,
  Subject,
  TaskAlt,
  Timeline,
  Troubleshoot,
  Warning,
  WarningAmber,
} from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useHistory } from 'react-router-dom';
//actions
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { detect } from 'detect-browser';
import moment from 'moment';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import createSalesAnalysisThread from 'redux/actions/Candidate/createSalesAnalysisThread';
import showAlert from 'redux/actions/Common/showAlert';
import checkSalesCallTranscriptStatus from 'redux/actions/Common/checkSalesCallTranscriptStatus';
import getSalesCallReviews from 'redux/actions/Common/getSalesCallReviews';
import analyzeSalesCall from 'redux/actions/Common/analyzeSalesCall';

import { PropTypes } from 'prop-types';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import LineGraph from 'components/LineGraph';
import parse from 'html-react-parser';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import checkSalesCallScoreStatus from 'redux/actions/Common/checkSalesCallScoreStatus';
import actionCodeBaseUrl from 'config/actionCodeBaseUrl';
import { capitalizeText, formatSeconds, getFileType } from 'utils';
import getSalesCallReviewsByUserId from 'redux/actions/Common/getSalesCallReviewsByUserId';
import getFeedbacksOnEntity from 'redux/actions/Candidate/getFeedbacksOnEntity';
import postFeedbackOnEntity from 'redux/actions/Candidate/postFeedbackOnEntity';
import getOrganizationConfigs from 'redux/actions/Common/getOrganizationConfigs';
import updateGlobalOrganizationConfig from 'redux/actions/Common/updateGlobalOrganizationConfig';
import syncDataWithHubspot from 'redux/actions/Common/syncDataWithHubspot';
import MediaPreview from 'components/@2024/MediaPreview';
import ParticipationPieChart from 'components/@2024/ParticipationPieChart';
import ReportPage from 'pages/ReportPage';
import getSalesCallReviewByToken from 'redux/actions/Common/getSalesCallReviewByToken';
import getPlaybookHistory from 'redux/actions/Common/getPlaybookHistory';
import reAnalyzeSalesCall from 'redux/actions/Common/reAnalyzeSalesCall';
import SkillTagInput from './components/skillTagInput';
import getConversationById from 'redux/actions/Candidate/getConversationById';
import getMessageResponse from 'redux/actions/Candidate/getMessageResponse';
import ProfileCompletion from 'components/ProfileCompletion';
import updateSalesReviewData from 'redux/actions/Common/updateSalesReviewData';
import reAnalyzeSalesCallv2 from 'redux/actions/Common/reAnalyzeSalesCallv2';
import AutoCompleteMUI from 'components/@2024/AutoCompleteMUI';
import { v4 as uuidv4 } from 'uuid';
import updateTeam from 'redux/actions/Common/updateTeam';
import getAllHubspotDeals from 'redux/actions/Common/getAllHubspotDeals';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import updateHubspotDeal from 'redux/actions/Common/updateHubspotDeal';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TooltipChart,
  Legend,
  ChartDataLabels,
);

const VideoPreview = memo(
  function VideoPreview({ selectedAudio, onDurationChange }) {
    return (
      <Box
        // flex={1}
        display={'flex'}
        flexDirection={'column'}
        sx={{ alignItems: 'center', justifyContent: 'flex-start' }}
      >
        {selectedAudio ? (
          selectedAudio?.type?.split('/')?.[0] === 'audio' ? (
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <Typography
                variant="body2"
                color={'text.secondary'}
                textAlign={'center'}
              >
                Selected File
              </Typography>
              <audio
                preload
                src={window.URL.createObjectURL(selectedAudio)}
                controls
                onDurationChange={onDurationChange}
              />
            </Box>
          ) : (
            <Box
              width={'90%'}
              display={'flex'}
              flexDirection={'column'}
              gap={1}
            >
              <Typography
                variant="body2"
                color={'text.secondary'}
                textAlign={'center'}
              >
                Selected File
              </Typography>
              <video
                style={{ width: '100%' }}
                preload={false}
                src={window.URL.createObjectURL(selectedAudio)}
                controls
                controlsList="nodownload"
                disablePictureInPicture
                onDurationChange={onDurationChange}
              />
            </Box>
          )
        ) : null}
      </Box>
    );
  },
  (pre, post) => {
    return pre?.selectedAudio === post?.selectedAudio;
  },
);

const activeUUID = uuidv4();

const SalesCallAnalysis = ({ uId, member, parentComponent, currentTeam }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params = new URLSearchParams(window.location.search);
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const organization = JSON.parse(localStorage.getItem('organization') || '{}');
  const { dashboardSidebarExpanded } = useSelector((state) => state.uiStates);
  const teamId = localStorage.getItem('teamId');
  const { uploadingFor, uploadPercentage } = useSelector(
    (state) => state.createSalesAnalysisThreadState,
  );
  const chatsScrollview = useRef(null);
  const { orgConfigs } = useSelector((state) => state.orgConfigs);
  const {
    analysisReviews,
    fetching,
    fetchingPagination,
    lastSeen,
    lastTime,
    emptyList,
  } = useSelector((state) => state.salesAnalysisReviews);
  const conversationDetailsById = useSelector(
    (state) => state.conversationDetailsById,
  );

  const { conversationDetails } = conversationDetailsById;

  const feedbacksOnEntity = useSelector((state) => state.feedbacksOnEntity);
  const { userDetails } = useSelector((state) => state.userDetails);
  const {
    showCompetitionDetailsOnTopbar,
    currentContextData,
    showBackButtonOnTopbar,
    currentNavigationLevel,
    loggedInUserTeamDetails,
  } = useSelector((state) => state.uiStates);
  const [loading, setLoading] = useState(false);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });

  const [currentTab, setCurrentTab] = useState('create');

  const [showPreviewSalesCall, setShowPreviewSalesCall] = useState(false);

  const [autoAnalysis, setAutoAnalysis] = useState(false);
  const [searchKeywords, setSearchKeywords] = useState([]);
  const [showAskDonna, setShowAskDonna] = useState(null);
  const [askAIQuestion, setAskAIQuestion] = useState('');
  const [generatingResponse, setGeneratingResponse] = useState(false);
  const [anchorElDetails, setAnchorElDetails] = useState(null);
  const [popperDetails, setPopperDetails] = useState(null);
  const [languages, setLanguages] = useState(['english']);
  const [activeAccount, setActiveAccount] = useState(activeUUID);

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const [anchorElAddAccount, setAnchorElAddAccount] = useState(null);
  const openAddAccountMenu = Boolean(anchorElAddAccount);
  const [addAccountFor, setAddAccountFor] = useState(null);

  const [steps, setSteps] = useState([
    {
      title: 'Upload Recording',
      subtitle: 'Please upload a call recording',
      icon: ({ active }) => (
        <CloudUpload
          sx={{
            color: active ? theme.palette.primary.main : '#677788',
          }}
        />
      ),
      completed: false,
      active: true,
    },
    {
      title: 'Analysis Options',
      subtitle:
        'Specify methodologies and playbook to analyze the call. Click Next when ready',
      icon: ({ active }) => (
        <Subject
          sx={{
            color: active ? theme.palette.primary.main : '#677788',
          }}
        />
      ),
      completed: false,
      active: false,
    },
    {
      title: 'Analyze the call',
      subtitle:
        'Please refer to the transcript and provide names of the call participants. Make sure you specify who is the main seller in the call. Click Analyze when done',
      icon: ({ active }) => (
        <Troubleshoot
          sx={{
            color: active ? theme.palette.primary.main : '#677788',
          }}
        />
      ),
      completed: false,
      active: false,
    },
  ]);

  const [activeStep, setActiveStep] = useState(0);
  const inputImage = useRef();
  const scrollDivRef = useRef();
  const scrollDiv1Ref = useRef();
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [invalidAudioFile, setInvalidAudioFile] = useState(false);

  const [accounts, setAccounts] = useState([]);
  const [accountName, setAccountName] = useState('');
  const [conversationTitle, setConversationTitle] = useState('');
  const [methods, setMethods] = useState(['meddpic']);
  const [playbook, setPlaybook] = useState('');

  const timerRef = useRef(null);
  const checkProgressTimer = useRef(null);

  const [showProgressOverlay, setShowProgressOverlay] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [transcriptReady, setTranscriptReady] = useState(false);
  const [transcriptionFailed, setTranscriptionFailed] = useState(false);
  const [uploadingCall, setUploadingCall] = useState(false);
  const [uploadingAudioId, setUploadingAudioId] = useState(null);
  const [saleReviewId, setSaleReviewId] = useState('');
  const [salesReview, setSalesReview] = useState(null);

  const [speaker, setSpeaker] = useState('Speaker 1');
  const [speakers, setSpeakers] = useState({});

  const [transcriptionInProgress, setTranscriptionInProgress] = useState([]);
  const [analysisInProgress, setAnalysisInProgress] = useState([]);

  const [viewType, setViewType] = useState('analysis');
  const [currentReport, setCurrentReport] = useState(null);
  const [currentParam, setCurrentParam] = useState('transcript');
  const [showPlaybook, setShowPlaybook] = useState(false);

  const [scriptReadingFillerWords, setScriptReadingFillerWords] = useState([]);
  const [scriptRepeatWords, setScriptRepeatWords] = useState([]);
  const [fillerWordsRegexp, setFillerWordsRegexp] = useState(null);
  const [repeatWordsRegexp, setRepeatWordRegexp] = useState(null);

  const [currentMethod, setCurrentMethod] = useState('meddpic');

  const [showReAnalyzeDrawer, setShowReAnalyzeDrawer] = useState(null);
  const browser = detect();
  const checkSalesCallScoreStatusTimer = useRef(null);
  const [scoreCalculationInProcess, setScoreCalculationInProcess] = useState(
    true,
  );

  const [invalidFile, setInvalidFile] = useState(false);
  const [showLimitExhausted, setShowLimitExhausted] = useState(false);
  const [showSyncHubspotDialog, setShowSyncHubspotDialog] = useState(false);
  const [meetingTitle, setMeetingTitle] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactFirstName, setContactFirstName] = useState('');
  const [contactLastName, setContactLastName] = useState('');
  const [organizer, setOrganizer] = useState('');
  const [dateTime, setDateTime] = useState(new Date().getTime());
  const [syncingWithHubspot, setSyncingWithHubspot] = useState(false);

  const [playbooks, setPlaybooks] = useState([]);
  const [selectedPlaybook, setSelectedPlaybook] = useState('select');
  const [playbookTitle, setPlaybookTitle] = useState('');

  const [salesCallType, setSalesCallType] = useState('outbound');

  const [showTranscriptionFailed, setShowTranscriptionFailed] = useState(null);
  const [retriggeringCall, setRetriggeringCall] = useState(false);

  const [searchError, setSearchError] = useState('');
  const [searchKeywordsMap, setSearchKeywordsMap] = useState({});

  const [searchKeywordOptions, setSearchKeywordOptions] = useState([]);

  const checkPresetQuestionsStatusTimer = useRef(null);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(null);
  const [fetchingConversationById, setFetchingConversationById] = useState(
    false,
  );

  const [accountEditable, setAccountEditable] = useState(false);
  const [wordsLimit, setWordsLimit] = useState(50);

  const [
    showSyncHubspotPropertiesDialog,
    setShowSyncHubspotPropertiesDialog,
  ] = useState(false);
  const [hubspotDeals, setHubspotDeals] = useState([]);
  const [selectedDeal, setSelectedDeal] = useState('');

  const searchInputRef = useRef(null);

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  const validateFile = (event) => {
    // console.log(event.target.duration);
    // console.log(
    //   Object.values(
    //     Object.values(userDetails?.custom?.plan?.features || {})?.find(
    //       (obj) => obj?.feature === 'call_analysis',
    //     )?.restrictions || {},
    //   )?.find(
    //     (obj) =>
    //       obj?.restriction === 'duration_of_sale_call_recording_analysis',
    //   )?.limit / 1000,
    // );
    if (activeStep === 0) {
      // if (activeStep === 0) {
      if (
        // (process.env.REACT_APP_TARGET_ENV === 'development' ||
        //   process.env.REACT_APP_TARGET_ENV === 'local') &&
        Math.round(event.target.duration) >
        Object.values(
          Object.values(userDetails?.custom?.plan?.features || {})?.find(
            (obj) => obj?.feature === 'call_analysis',
          )?.restrictions || {},
        )?.find(
          (obj) =>
            obj?.restriction === 'duration_of_sale_call_recording_analysis',
        )?.limit /
          1000
      ) {
        setInvalidFile(
          `You are only allowed to upload a file of upto ${Math.round(
            Object.values(
              Object.values(userDetails?.custom?.plan?.features || {})?.find(
                (obj) => obj?.feature === 'call_analysis',
              )?.restrictions || {},
            )?.find(
              (obj) =>
                obj?.restriction === 'duration_of_sale_call_recording_analysis',
            )?.limit /
              (1000 * 60),
          )} minutes duration`,
        );
        setActiveStep(0);
      } else {
        setInvalidFile(false);
        setActiveStep(1);
      }
    }
    // }
  };

  const onFileChange = (event) => {
    console.log(event.target.files[0]?.size > 200 * 1024 * 1024);
    handleTrackEvent(event, 'recording_file_selected_or_changed');
    if (
      event.target.files[0] &&
      (event.target.files[0].type.includes('audio') ||
        event.target.files[0].type.includes('video'))
      //   &&
      // (!(
      //   event.target.files[0]?.size > 200 * 1024 * 1024 &&
      //   !(
      //     process.env.REACT_APP_TARGET_ENV === 'local' ||
      //     process.env.REACT_APP_TARGET_ENV === 'development'
      //   )
      // ) ||
      //   userInfo?.id === 'p1NmkUlUCabB0OJIs2UnEapBEJy2')
    ) {
      setSelectedAudio(event.target.files[0]);
      // setActiveStep(1);
    } else {
      // if (
      //   event.target.files[0]?.size > 200 * 1024 * 1024 &&
      //   !(
      //     process.env.REACT_APP_TARGET_ENV === 'local' ||
      //     process.env.REACT_APP_TARGET_ENV === 'development'
      //   )
      // ) {
      //   dispatch(
      //     logError({
      //       message: `User with userId ${userInfo?.id} tried to upload more than 200 MB file`,
      //       data: {
      //         formatted: {
      //           errorText: 'User tried to upload more than 200 MB file',
      //           severity: 'error',
      //         },
      //         raw: {
      //           errorText: 'User tried to upload more than 200 MB file',
      //           severity: 'error',
      //         },
      //       },
      //     }),
      //   );
      //   setInvalidFile(
      //     'The video you are trying to analyze is larger than the limit supported in this plan. Please get in touch with your Qualification AI success manager or write to support@qualification.ai to get it analyzed. ',
      //   );
      // } else {
      inputImage.current.value = null;
      setInvalidAudioFile(true);
      // }
    }
  };

  const handleChange = (e, update) => {
    if (e.target.checked) {
      setMethods((prev) => [...prev, e.target.value]);
    } else {
      setMethods((prev) => prev.filter((item) => item !== e.target.value));
    }
  };

  const handleLanguageChange = (e) => {
    if (e.target.checked) {
      setLanguages((prev) => [...prev, e.target.value]);
    } else {
      setLanguages((prev) => prev.filter((item) => item !== e.target.value));
    }
  };

  const resetThread = () => {
    setActiveStep(0);
    setSelectedAudio(null);
    setSalesReview(null);
    setSaleReviewId('');
    setProgressValue(0);
    setTranscriptReady(false);
    setTranscriptionFailed(false);
    setConversationTitle('');
    setInvalidFile(false);
  };

  const skillsTags = (value) => {
    const keywords = value.map((skils) => skils.value);
    setSearchKeywords(keywords);
  };

  const resetSearch = (reset) => {
    dispatch(
      getSalesCallReviews(
        {
          accountTagId:
            activeAccount !== activeUUID && !reset ? activeAccount : null,
          userId: uId || null,
          // keywords: '',
          lastSeen: null,
          lastTime: null,
          size: 10,
          teamId: currentTeam?.id || teamId || null,
        },
        (data) => {
          setSearchKeywords([]);
        },
      ),
    );
  };

  const handleAccountFilter = () => {
    dispatch(
      getSalesCallReviews(
        {
          accountTagId: activeAccount !== activeUUID ? activeAccount : null,
          lastSeen: null,
          lastTime: null,
          size: 10,
          teamId: currentTeam?.id || teamId || null,
        },
        (data) => {
          if (data?.length === 0) {
            history.push(`/sales-call-analysis?new=true`);
          }
        },
      ),
    );
  };

  const handleAccountUpdate = (salesReviewData) => {
    if (accountName?.trim()) {
      const newID = uuidv4();
      dispatch(
        updateSalesReviewData(
          salesReviewData?.id || addAccountFor?.id,
          {
            accountTagId:
              accounts?.find((ac) => ac?.name === accountName)?.id || newID,
          },
          (data) => {
            setAnchorElAddAccount(null);
            setAddAccountFor(null);
            setAccountName('');
            dispatch({
              type: 'UPDATE_SALES_CALL_ANALYSIS',
              payload: {
                id: salesReviewData?.id || addAccountFor?.id,
                data: {
                  ...(salesReviewData || addAccountFor),
                  accountTagId:
                    accounts?.find((ac) => ac?.name === accountName)?.id ||
                    newID,
                },
              },
            });
            if (!accounts?.find((ac) => ac?.name === accountName)) {
              dispatch(
                updateGlobalOrganizationConfig(
                  {
                    accountTagMap: [
                      ...accounts,
                      {
                        id: newID,
                        name: accountName,
                      },
                    ]?.reduce((acc, curr) => {
                      acc[curr?.id] = curr?.name;
                      return acc;
                    }, {}),
                  },
                  () => {
                    dispatch(getOrganizationConfigs(() => {}));
                  },
                ),
              );
            }
          },
          () => {
            setAnchorElAddAccount(null);
            setAddAccountFor(null);
          },
        ),
      );
    } else {
      setAnchorElAddAccount(null);
      setAddAccountFor(null);
    }
  };

  const handleUpload = () => {
    if (
      userDetails?.custom?.userUsage?.currentUsage?.[
        'number_of_sale_call_recording_analysis'
      ] >=
      Object.values(
        Object.values(userDetails?.custom?.plan?.features || {})?.find(
          (obj) => obj?.feature === 'call_analysis',
        )?.restrictions || {},
      )?.find(
        (obj) => obj?.restriction === 'number_of_sale_call_recording_analysis',
      )?.limit
    ) {
      setShowLimitExhausted(true);
    } else {
      setUploadingCall(true);
      handleTrackEvent(null, 'upload_recording');
      const newID1 = uuidv4();
      if (!accounts?.find((ac) => ac?.name === accountName)) {
        dispatch(
          updateGlobalOrganizationConfig(
            {
              accountTagMap: [
                ...accounts,
                {
                  id: newID1,
                  name: accountName,
                },
              ]?.reduce((acc, curr) => {
                acc[curr?.id] = curr?.name;
                return acc;
              }, {}),
            },
            () => {
              dispatch(getOrganizationConfigs(() => {}));
            },
          ),
        );
      }
      dispatch(
        createSalesAnalysisThread(
          {
            accountTagId: !accounts?.find((ac) => ac?.name === accountName)
              ? newID1
              : accounts?.find((ac) => ac?.name === accountName)?.id,
            contentType: selectedAudio?.type?.split(';')?.[0],
            mediaType: 'audio',
            playbook: playbook?.trim() ? true : false,
            playbookText: playbook,
            title: conversationTitle?.trim(),
            playbookTitle: playbookTitle,
            playbookHistoryId: selectedPlaybook,
            analysisMethods: methods,
            salesCallType,
            fileSize: selectedAudio?.size,
            languages:
              orgConfigs?.languagesForAnalysis?.length > 1 &&
              userDetails?.userSetting?.languagesForAnalysis?.length > 1
                ? languages
                : null,

            teamId: currentTeam?.id || teamId || null,
          },
          selectedAudio,
          (data) => {
            setAccountName('');
            if (autoAnalysis) {
              setCurrentTab('list');
              dispatch(
                getUserDetails((data) => {
                  history.push('/sales-call-analysis');
                }),
              );
              setViewType('analysis');
              resetThread();
              setUploadingCall(false);
              dispatch(
                showAlert({
                  message:
                    'File is uploaded successfully. We will notify when it is analyzed.',
                  showCross: true,
                  title: null,
                  type: 'success',
                  autoHideDuration: 5000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
            } else {
              dispatch(getUserDetails((data) => {}));
              setUploadingCall(false);
              dispatch(
                showAlert({
                  message: 'File is uploaded successfully.',
                  showCross: true,
                  title: null,
                  type: 'success',
                  autoHideDuration: 5000,
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
              setActiveStep(2);
              setSaleReviewId(data?.id);
            }
          },
          (audioId) => {
            setUploadingAudioId(audioId);
          },
          () => {
            setUploadingCall(false);
            dispatch(
              showAlert({
                message: 'Oops something went wrong. Please try again later!',
                showCross: true,
                title: null,
                type: 'error',
                autoHideDuration: 5000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          },
        ),
      );
    }
  };

  const handleAnalyze = (srId, mode, ar, spks, spk, cb) => {
    if (
      userDetails?.custom?.userUsage?.currentUsage?.[
        'number_of_sale_call_recording_analysis'
      ] >=
      Object.values(
        Object.values(userDetails?.custom?.plan?.features || {})?.find(
          (obj) => obj?.feature === 'call_analysis',
        )?.restrictions || {},
      )?.find(
        (obj) => obj?.restriction === 'number_of_sale_call_recording_analysis',
      )?.limit
    ) {
      setShowLimitExhausted(true);
    } else {
      if (mode === 'create') {
        handleTrackEvent(null, 'new_analyze_call_started');
      } else {
        handleTrackEvent(null, 'reanalyze_call_started');
      }
      setAnalysisInProgress((prev) => [...prev, srId]);
      dispatch(
        analyzeSalesCall(
          srId,
          {
            playbookText: playbook,
            speakers: spks ? spks : speakers,
            analysisMethods: methods,
            playbookTitle: playbookTitle,
            playbookHistoryId: selectedPlaybook,
            salesCallType,
            teamId: currentTeam?.id || teamId || null,
            // languages: orgConfigs?.multiLanguageSupport ? languages : null,
          },
          spk ? spk : speaker,
          (data) => {
            dispatch(getUserDetails(() => {}));
            const updatedAnalysisInProgress = analysisInProgress.filter(
              (ap) => ap !== srId,
            );
            setAnalysisInProgress(updatedAnalysisInProgress);
            if (showTranscriptionFailed) {
              setShowTranscriptionFailed(null);
            }
            if (mode === 'create') {
              setSalesReview(data);
            } else {
              setShowReAnalyzeDrawer(null);
              setSalesReview(data);
              dispatch({
                type: 'UPDATE_SALES_CALL_ANALYSIS',
                payload: {
                  id: srId,
                  data: data,
                },
              });
            }
            setViewType('report');
            setSaleReviewId(srId);
            if (userDetails?.custom?.integration?.hubSpot) {
              setShowSyncHubspotDialog(true);
            }
            if (typeof cb === 'function') {
              cb();
            }
          },
          () => {
            const updatedAnalysisInProgress = analysisInProgress.filter(
              (ap) => ap !== srId,
            );
            setAnalysisInProgress(updatedAnalysisInProgress);
            dispatch(
              showAlert({
                message: 'Something went wrong. Please try again!',
                showCross: true,
                title: null,
                type: 'error',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
            if (typeof cb === 'function') {
              cb();
            }
          },
        ),
      );
    }
  };

  const handleReAnalyze = (mediaId) => {
    if (
      userDetails?.custom?.userUsage?.currentUsage?.[
        'number_of_sale_call_recording_analysis'
      ] >=
      Object.values(
        Object.values(userDetails?.custom?.plan?.features || {})?.find(
          (obj) => obj?.feature === 'call_analysis',
        )?.restrictions || {},
      )?.find(
        (obj) => obj?.restriction === 'number_of_sale_call_recording_analysis',
      )?.limit
    ) {
      setShowLimitExhausted(true);
    } else {
      setRetriggeringCall(true);
      dispatch(
        reAnalyzeSalesCallv2(
          mediaId,
          {
            languages: languages,
            reTranscriptionReason:
              showTranscriptionFailed?.status === 'created'
                ? 'not_started'
                : showTranscriptionFailed?.status === 'failed'
                ? 'failed'
                : 'language_change',
          },
          (data) => {
            setRetriggeringCall(false);
            setSaleReviewId(showTranscriptionFailed?.id);
            dispatch({
              type: 'UPDATE_SALES_CALL_ANALYSIS',
              payload: {
                id: showTranscriptionFailed?.id,
                data: {
                  ...showTranscriptionFailed,
                  status: 'pending',
                },
              },
            });
          },
          () => {
            setRetriggeringCall(false);
          },
        ),
      );
    }
  };

  const handleListScroll = (e, paginate) => {
    // console.log(e.target.scrollTop + e.target.clientHeight);
    // console.log(e.target.clientHeight);
    // console.log(e.target.scrollHeight);
    // console.log(
    //   e.target.scrollTop + e.target.clientHeight + 1 >= e.target.scrollHeight,
    // );
    if (
      (e.target.scrollTop + e.target.clientHeight + 1 >=
        e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        analysisReviews &&
        analysisReviews.length % 10 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      if (uId) {
        dispatch(
          getSalesCallReviews(
            {
              accountTagId: activeAccount !== activeUUID ? activeAccount : null,
              userId: uId,
              // keywords: searchKeywords?.join(','),
              lastSeen: lastSeen,
              lastTime: lastTime,
              size: 10,
              teamId: currentTeam?.id || teamId || null,
            },
            () => {},
          ),
        );
      } else {
        dispatch(
          getSalesCallReviews(
            {
              accountTagId: activeAccount !== activeUUID ? activeAccount : null,
              // keywords: searchKeywords?.join(','),
              lastSeen: lastSeen,
              lastTime: lastTime,
              size: 10,
              teamId: currentTeam?.id || teamId || null,
            },
            () => {},
          ),
        );
      }
    }
  };

  useEffect(() => {
    if (accountEditable) {
      setAccountName(
        accounts?.find((ac) => ac?.id === accountEditable?.accountTagId)?.name,
      );
    }
  }, [accountEditable, accounts]);

  useEffect(() => {
    if (selectedPlaybook === 'select') {
      if (
        showReAnalyzeDrawer &&
        analysisReviews?.find((ar) => ar?.id === showReAnalyzeDrawer)?.playbook
      ) {
        setPlaybook(
          analysisReviews?.find((ar) => ar?.id === showReAnalyzeDrawer)
            ?.playbook,
        );
      } else {
        if (orgConfigs?.playbook) {
          setPlaybook(orgConfigs?.playbook);
        }
      }
    } else {
      setPlaybook(
        playbooks?.find((pb) => pb?.id === selectedPlaybook)?.playbook || '',
      );
    }
  }, [orgConfigs, showReAnalyzeDrawer, selectedPlaybook]);

  useEffect(() => {
    if (
      uploadingFor &&
      Object.keys(uploadingFor).length > 0 &&
      uploadingFor?.[uploadingAudioId]
    ) {
      setProgressValue(uploadPercentage?.[uploadingAudioId]);
    }
  }, [uploadingFor, uploadPercentage, uploadingAudioId]);

  useEffect(() => {
    if (teamId) {
      setSalesCallType(loggedInUserTeamDetails?.salesCallType);
    } else {
      if (userDetails?.userSetting?.salesCallType) {
        setSalesCallType(userDetails?.userSetting?.salesCallType);
      } else if (orgConfigs?.salesCallType) {
        setSalesCallType(orgConfigs?.salesCallType);
      } else {
        setSalesCallType('outbound');
      }
    }
    if (userDetails?.userSetting?.defaultLanguagesForAnalysis?.length > 0) {
      setLanguages(userDetails?.userSetting?.defaultLanguagesForAnalysis);
    } else if (orgConfigs?.defaultLanguageForAnalysis?.length > 0) {
      setLanguages(orgConfigs?.defaultLanguageForAnalysis);
    } else {
      setLanguages(['english']);
    }
  }, [orgConfigs, userDetails, loggedInUserTeamDetails, teamId]);

  useEffect(() => {
    if ((activeStep === 2 || showTranscriptionFailed) && saleReviewId) {
      dispatch(
        checkSalesCallTranscriptStatus(saleReviewId, (data) => {
          // console.log(data);
          if (data?.status === 'failed') {
            setTranscriptReady(false);
            setTranscriptionFailed(true);
            dispatch(
              showAlert({
                message: 'Something went wrong. Please try again!',
                showCross: true,
                title: null,
                type: 'error',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          } else if (data?.status !== 'failed' && data?.status !== 'success') {
            checkProgressTimer.current = setInterval(() => {
              dispatch(
                checkSalesCallTranscriptStatus(saleReviewId, (data) => {
                  if (data?.status === 'success') {
                    clearInterval(checkProgressTimer.current);
                    setTranscriptionFailed(false);
                    setTranscriptReady(true);
                    setSalesReview(data?.saleReview);
                    if (showTranscriptionFailed) {
                      setShowTranscriptionFailed(data?.saleReview);
                    }
                  } else if (data?.status === 'failed') {
                    setTranscriptReady(false);
                    setTranscriptionFailed(true);
                    clearInterval(checkProgressTimer.current);
                    dispatch(
                      showAlert({
                        message: 'Something went wrong. Please try again!',
                        showCross: true,
                        title: null,
                        type: 'error',
                        autoHideDuration: 2000,
                        vertical: 'top',
                        horizontal: 'center',
                      }),
                    );
                  }
                }),
              );
            }, 5000);
          }
        }),
      );
    } else if (activeStep !== 2 && saleReviewId) {
      setTranscriptReady(false);
      clearInterval(checkProgressTimer.current);
      setSaleReviewId('');
    }
    if (activeStep === 1) {
      setConversationTitle(
        selectedAudio?.name
          ?.split('.')
          .slice(0, selectedAudio?.name.split('.')?.length - 1)
          ?.join('.') || '',
      );
    }
  }, [activeStep, saleReviewId, showTranscriptionFailed]);

  useEffect(() => {
    if (
      showTranscriptionFailed &&
      (showTranscriptionFailed?.status === 'pending' ||
        showTranscriptionFailed?.status === 'in_process')
    ) {
      setSaleReviewId(showTranscriptionFailed?.id);
    }
  }, [showTranscriptionFailed]);

  useEffect(() => {
    console.log(salesReview);
    if (salesReview) {
      setSpeakers(salesReview?.speakers);
      setMethods(salesReview?.analysisMethodologies || []);
      // setLanguages(salesReview?.languages || []);
    }
  }, [salesReview]);

  useEffect(() => {
    if (showReAnalyzeDrawer) {
      // setActiveStep(2);
      setSpeakers(salesReview?.speakers);
      setSpeaker(salesReview?.speaker || 'Speaker 1');
      setSalesCallType(salesReview?.salesCallType || 'outbound');
      setMethods([...(salesReview?.analysisMethodologies || [])]);
      // setLanguages(salesReview?.languages || []);
    }
  }, [showReAnalyzeDrawer]);

  useEffect(() => {
    if (currentTab === 'list' && viewType === 'analysis') {
      if (uId) {
        dispatch(
          getSalesCallReviews(
            {
              accountTagId: activeAccount !== activeUUID ? activeAccount : null,
              userId: uId,
              // keywords: searchKeywords?.join(','),
              lastSeen: null,
              lastTime: null,
              size: 10,
              teamId: currentTeam?.id || teamId || null,
            },
            () => {},
          ),
        );
      } else {
        dispatch(
          getSalesCallReviews(
            {
              accountTagId: activeAccount !== activeUUID ? activeAccount : null,
              // keywords: searchKeywords?.join(','),
              lastSeen: null,
              lastTime: null,
              size: 10,
              teamId: currentTeam?.id || teamId || null,
            },
            (data) => {},
          ),
        );
      }
    }
  }, [currentTab, viewType]);

  useEffect(() => {
    // if (currentTab === 'create') {
    if (teamId) {
      setAccounts(
        Object.keys(loggedInUserTeamDetails?.accountTagMap || {})?.map(
          (key) => {
            return {
              id: key,
              name: loggedInUserTeamDetails?.accountTagMap?.[key],
            };
          },
        ) || [],
      );
      setMethods(loggedInUserTeamDetails?.selectedMethodologies || []);
      setAutoAnalysis(
        loggedInUserTeamDetails?.integrations?.generalAutoAnalysis,
      );
    } else {
      if (orgConfigs) {
        setAccounts(
          Object.keys(orgConfigs?.accountTagMap || {})?.map((key) => {
            return {
              id: key,
              name: orgConfigs?.accountTagMap?.[key],
            };
          }) || [],
        );
        setMethods(orgConfigs?.selectedMethodologies || []);
        setAutoAnalysis(orgConfigs?.isGeneralAutoAnalysis);
        setSearchKeywordOptions(
          [
            ...(orgConfigs?.tags || []),
            ...(orgConfigs?.saleReviewFileNames || []),
          ]?.sort((a, b) => {
            if (
              orgConfigs?.tags?.includes(a) &&
              !orgConfigs?.tags?.includes(b)
            ) {
              return -1;
            } else {
              return 1;
            }
          }),
        );
      }
    }
    // }
  }, [orgConfigs, teamId, loggedInUserTeamDetails]);

  useEffect(() => {
    if (params && params.get('new') === 'true') {
      setCurrentTab('create');
      setViewType('analysis');
      dispatch(getOrganizationConfigs((data) => {}));
    } else {
      setCurrentTab('list');
      setViewType('analysis');
    }
  }, [window.location.href, currentTab]);

  useEffect(() => {
    if (params && params.get('rtid')) {
      dispatch(
        getSalesCallReviewByToken(params.get('rtid'), (data) => {
          console.log(data);
          setSalesReview(data);
          setViewType('report');
          setSaleReviewId(params.get('rtid'));
        }),
      );
    }
  }, [params.get('rtid')]);

  useEffect(() => {
    if (!showBackButtonOnTopbar) {
      setViewType('analysis');
      setCurrentTab('list');
      resetThread();
    }
  }, [showBackButtonOnTopbar]);

  useEffect(() => {
    if (viewType === 'report') {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: true,
        },
      });
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: {
            ...currentContextData,
            title: salesReview?.title || '',
          },
        },
      });
    } else {
      if (!(params && params.get('new') === 'true') && !uId) {
        history.push('/sales-call-analysis');
      }
      if (!uId) {
        dispatch({
          type: 'UPDATE_UI_STATE',
          payload: {
            key: 'showBackButtonOnTopbar',
            value: false,
          },
        });
        dispatch({
          type: 'UPDATE_UI_STATE',
          payload: {
            key: 'currentContextData',
            value: {
              ...currentContextData,
              title: '',
            },
          },
        });
      }
    }
  }, [viewType]);

  useEffect(() => {
    if (member && uId) {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: {
            ...currentContextData,
            name: member?.fullName,
            email: member?.email,
          },
        },
      });
    }
  }, [member, uId]);

  useEffect(() => {
    if (playbooks && playbooks.length > 0) {
      if (showReAnalyzeDrawer) {
        setSelectedPlaybook(
          analysisReviews?.find((ar) => ar?.id === showReAnalyzeDrawer)
            ?.playbookHistoryId || playbooks?.find((pb) => pb?.selected)?.id,
        );
      } else {
        setSelectedPlaybook(playbooks?.find((pb) => pb?.selected)?.id);
      }
    }
  }, [playbooks]);

  useEffect(() => {
    if (selectedPlaybook) {
      setPlaybookTitle(
        playbooks?.find((pb) => pb?.id === selectedPlaybook)?.tags?.[0] || '',
      );
    }
  }, [selectedPlaybook]);

  useEffect(() => {
    if (activeStep === 1 || showReAnalyzeDrawer) {
      dispatch(
        getPlaybookHistory(teamId || null, (data) => {
          setPlaybooks([data?.selectedPlaybook, ...(data?.history || [])]);
        }),
      );
    }
  }, [activeStep, showReAnalyzeDrawer]);

  useEffect(() => {
    if (
      searchKeywords &&
      searchKeywords.length > 0 &&
      salesReview?.transcript
    ) {
      setSearchKeywordsMap(
        searchKeywords.reduce((acc, curr) => {
          acc[curr] =
            salesReview?.transcript?.match(new RegExp(curr, 'ig'))?.length || 0;
          return acc;
        }, {}),
      );
    }
  }, [searchKeywords, salesReview]);

  useEffect(() => {
    if (
      (parentComponent === 'teamSettings' && currentNavigationLevel > 2) ||
      (parentComponent === 'customerSettings' && currentNavigationLevel > 3)
    ) {
      setViewType('report');
    } else {
      setViewType('analysis');
    }
  }, [currentNavigationLevel]);

  useEffect(() => {
    if (showAskDonna) {
      setFetchingConversationById(true);
      dispatch(
        getConversationById(
          showAskDonna?.conversationId,
          showAskDonna?.speaker,
          (data) => {
            setFetchingConversationById(false);
            console.log(data);
            if (
              data?.presetQuestionStatus === 'completed' ||
              data?.presetQuestionStatus === 'failed'
            ) {
              clearInterval(checkPresetQuestionsStatusTimer.current);
              if (chatsScrollview?.current) {
                chatsScrollview?.current?.scroll({
                  top: chatsScrollview?.current?.scrollHeight,
                  behaviour: 'smooth',
                });
              }
            } else {
              checkPresetQuestionsStatusTimer.current = setInterval(() => {
                dispatch(
                  getConversationById(
                    showAskDonna?.conversationId,
                    showAskDonna?.speaker,
                    (data) => {
                      if (
                        data?.presetQuestionStatus === 'completed' ||
                        data?.presetQuestionStatus === 'failed'
                      ) {
                        clearInterval(checkPresetQuestionsStatusTimer.current);
                      }
                    },
                  ),
                );
              }, 5000);
            }
          },
        ),
      );
    }
  }, [showAskDonna]);

  useEffect(() => {
    if (showSyncHubspotPropertiesDialog) {
      dispatch(
        getAllHubspotDeals((data) => {
          setHubspotDeals(data?.deals || []);
        }),
      );
    }
  }, [showSyncHubspotPropertiesDialog]);

  useEffect(() => {
    if (uId) {
      dispatch(
        getSalesCallReviews(
          {
            userId: uId,
            accountTagId: activeAccount !== activeUUID ? activeAccount : null,
            lastSeen: null,
            lastTime: null,
            size: 10,
            teamId: currentTeam?.id || teamId || null,
          },
          () => {},
        ),
      );
    } else {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: null,
        },
      });
      dispatch(getOrganizationConfigs((data) => {}));
      dispatch(getUserDetails((data) => {}));
      dispatch(
        getSalesCallReviews(
          {
            accountTagId:
              activeAccount !== activeUUID
                ? activeAccount
                  ? activeAccount
                  : null
                : null,
            lastSeen: null,
            lastTime: null,
            size: 10,
            teamId: currentTeam?.id || teamId || null,
          },
          (data) => {
            if (data?.length === 0) {
              history.push(`/sales-call-analysis?new=true`);
            }
          },
        ),
      );
    }
    return () => {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: false,
        },
      });
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: null,
        },
      });
    };
  }, []);

  // console.log(teamId);
  // console.log(selectedDeal);
  // console.log(hubspotDeals);
  // console.log(orgConfigs?.hubspotDealProperties);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: isLoggedIn ? 'calc(100% - 80px)' : 1,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? 'calc(100% - 240px)'
                : 'calc(100% - 80px)'
              : 1,
        },
        marginLeft: {
          xs: '0px',
          sm: isLoggedIn ? '80px' : 0,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? '240px'
                : '80px'
              : 0,
        },
        height: 1,
        minHeight:
          isLoggedIn && !uId ? 'calc(100vh - 60px)' : 'calc(100vh - 146px)',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        height={1}
      >
        <Box
          flex={{ xs: 'auto', sm: 'auto', md: 1 }}
          display={'flex'}
          flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
          alignItems={{
            xs: 'start',
            sm: 'start',
            md: currentTab === 'create' ? 'center' : 'start',
          }}
          justifyContent={{
            xs: 'start',
            sm: 'start',
            md: currentTab === 'create' ? 'center' : 'flex-start',
          }}
          height={1}
          gap={2}
          sx={{
            borderRight: '1px solid #f1f1f1',
            minHeight: isXs
              ? 'auto'
              : viewType === 'report'
              ? 'calc(100vh - 130px)'
              : 'calc(100vh - 60px)',
            padding: uId ? 0 : 2,
            paddingTop: viewType === 'report' || uId ? 0 : 1,
          }}
        >
          {viewType === 'analysis' && currentTab === 'create' ? (
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={4}
              alignItems={'center'}
            >
              {userDetails?.teamRoles?.includes('TEAM_ADMIN') ||
              userDetails?.orgRoles?.includes('ORG_ADMIN') ||
              userDetails?.orgRoles?.includes('WL_ORG_ADMIN') ? (
                <FormControl component="fieldset" variant="standard">
                  <FormGroup row>
                    <FormControlLabel
                      checked={autoAnalysis}
                      control={
                        <Checkbox
                          size="small"
                          value={autoAnalysis}
                          name="autoAnalysis"
                        />
                      }
                      label={'Auto Analysis'}
                      onChange={(e) => {
                        setAutoAnalysis(e.target.checked);
                        if (teamId) {
                          dispatch(
                            updateTeam(
                              teamId,
                              {
                                generalAutoAnalysis: e.target.checked,
                              },
                              (data) => {
                                dispatch({
                                  type: 'UPDATE_UI_STATE',
                                  payload: {
                                    key: 'loggedInUserTeamDetails',
                                    value: data,
                                  },
                                });
                              },
                              () => {},
                            ),
                          );
                        } else {
                          dispatch(
                            updateGlobalOrganizationConfig(
                              {
                                textInLogo: orgConfigs?.textInLogo,
                                generalAutoAnalysis: e.target.checked,
                              },
                              () => {
                                dispatch(getOrganizationConfigs(() => {}));
                              },
                            ),
                          );
                        }
                      }}
                    />
                  </FormGroup>
                </FormControl>
              ) : (
                <Tooltip
                  arrow
                  placement="top"
                  title={'Contact Admin to have it turned on or off'}
                >
                  <Typography color={'text.secondary'}>
                    Auto Analysis | {autoAnalysis ? 'On' : 'Off'}
                  </Typography>
                </Tooltip>
              )}
              {activeStep === 0 ? (
                <Typography
                  variant="body1"
                  color={'text.secondary'}
                  textAlign={'center'}
                  sx={{ fontSize: 18 }}
                >
                  {'Please upload a call recording'}
                </Typography>
              ) : activeStep === 1 ? (
                <Typography
                  variant="body1"
                  color={'text.secondary'}
                  textAlign={'center'}
                  sx={{ fontSize: 18 }}
                >
                  {
                    'Specify methodologies and playbook to analyze the call. Click Next when ready'
                  }
                </Typography>
              ) : activeStep === 2 ? (
                <Typography
                  variant="body1"
                  color={'text.secondary'}
                  textAlign={'center'}
                  sx={{ fontSize: 18 }}
                >
                  {
                    'Please refer to the transcript and provide names of the call participants. Make sure you specify who is the main seller in the call. Click Analyze when done'
                  }
                </Typography>
              ) : null}
              <VideoPreview
                selectedAudio={selectedAudio}
                onDurationChange={validateFile}
              />
              <Box display={'flex'} flexDirection={'column'} gap={1} flex={1}>
                {steps?.map((step, index) => (
                  <Box
                    key={index}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                    // alignItems={'center'}
                  >
                    <Box
                      display={'flex'}
                      alignItems={'stretch'}
                      gap={1}
                      sx={
                        {
                          // cursor: 'pointer',
                        }
                      }
                      // onClick={() => setActiveStep(index)}
                    >
                      <Box
                        sx={{
                          // border: `1px solid ${
                          //   activeStep === index
                          //     ? theme.palette.primary.main
                          //     : '#677788'
                          // }`,
                          p: 1,
                          // background:
                          //   activeStep === index
                          //     ? theme.palette.primary.main
                          //     : '#fff',
                          borderRadius: 2,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          variant="body1"
                          color={
                            activeStep === index
                              ? theme.palette.primary.main
                              : '#677788'
                          }
                        >
                          Step {index + 1}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          // minWidth: 300,
                          // width: 500,
                          // border: `1px solid ${
                          //   activeStep === index
                          //     ? theme.palette.primary.main
                          //     : '#677788'
                          // }`,
                          p: 1,
                          // background:
                          //   activeStep === index
                          //     ? theme.palette.primary.main
                          //     : '#fff',
                          borderRadius: 2,
                          display: 'flex',
                          alignItems: 'center',
                          // justifyContent: 'center',
                          gap: 2,
                        }}
                      >
                        {step.icon({ active: activeStep === index })}
                        <Typography
                          variant="body1"
                          color={
                            activeStep === index
                              ? theme.palette.primary.main
                              : '#677788'
                          }
                        >
                          {step.title}
                          {autoAnalysis && index === 2 ? ' (Automatic)' : ''}
                        </Typography>
                      </Box>
                    </Box>
                    {index !== steps.length - 1 ? (
                      <Divider
                        orientation="vertical"
                        sx={{
                          background: '#d3d3d3',
                          height: 25,
                          width: '2px',
                          marginLeft: 2.5,
                        }}
                      />
                    ) : null}
                  </Box>
                ))}
              </Box>
            </Box>
          ) : viewType === 'analysis' && currentTab === 'list' ? (
            <Box display={'flex'} flexDirection={'column'} gap={1} width={1}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                gap={1}
              >
                {(isXs ||
                  userDetails?.orgRoles?.includes('ORG_ADMIN') ||
                  userDetails?.orgRoles?.includes('WL_ORG_ADMIN')) &&
                analysisReviews &&
                analysisReviews?.length > 0 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ marginLeft: 'auto' }}
                    onClick={() => history.push(`sales-call-analysis?new=true`)}
                  >
                    Upload Recording
                  </Button>
                ) : null}
              </Box>
              {searchError ? (
                <Typography
                  variant="caption"
                  sx={{ color: theme.palette.error.main }}
                >
                  {searchError}
                </Typography>
              ) : null}
              {fetching ? (
                <Box
                  width={1}
                  height={1}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'column'}
                >
                  <CircularProgress size={20} />
                  <Typography variant="body1" color={'text.primary'} mt={4}>
                    Loading ...
                  </Typography>
                </Box>
              ) : analysisReviews && analysisReviews?.length > 0 ? (
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                  <TableContainer
                    component={Paper}
                    sx={{
                      minHeight: uId
                        ? 'calc(100vh - 90px)'
                        : (isXs ||
                            userDetails?.orgRoles?.includes('ORG_ADMIN') ||
                            userDetails?.orgRoles?.includes('WL_ORG_ADMIN')) &&
                          analysisReviews &&
                          analysisReviews?.length > 0
                        ? 'calc(100vh - 130px)'
                        : 'calc(100vh - 90px)',
                      maxHeight: uId
                        ? 'calc(100vh - 90px)'
                        : (isXs ||
                            userDetails?.orgRoles?.includes('ORG_ADMIN') ||
                            userDetails?.orgRoles?.includes('WL_ORG_ADMIN')) &&
                          analysisReviews &&
                          analysisReviews?.length > 0
                        ? 'calc(100vh - 130px)'
                        : 'calc(100vh - 90px)',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                      },
                    }}
                    onScroll={handleListScroll}
                  >
                    <Table
                      sx={{ minWidth: 750 }}
                      stickyHeader
                      aria-label="simple table"
                    >
                      <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                            >
                              Title
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Box
                              display={'flex'}
                              justifyContent={'center'}
                              alignItems={'center'}
                              gap={1}
                            >
                              <Typography
                                variant={'caption'}
                                fontWeight={700}
                                sx={{
                                  textTransform: 'uppercase',
                                  // textAlign: 'center',
                                }}
                              >
                                Account
                              </Typography>
                              <FilterList
                                sx={{
                                  fontSize: 18,
                                  color: theme.palette.primary.main,
                                  cursor: 'pointer',
                                }}
                                onClick={(e) => {
                                  if (anchorEl) {
                                    setAnchorEl(null);
                                  } else {
                                    setAnchorEl(e.currentTarget);
                                  }
                                }}
                              />
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                            >
                              Date
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                              textAlign={'center'}
                              // align="center"
                            >
                              Media
                            </Typography>
                          </TableCell>
                          {/* {orgConfigs?.multiLanguageSupport ? (
                            <TableCell align="center">
                              <Typography
                                variant={'caption'}
                                fontWeight={700}
                                sx={{ textTransform: 'uppercase' }}
                                textAlign={'center'}
                                // align="center"
                              >
                                Languages
                              </Typography>
                            </TableCell>
                          ) : null} */}
                          <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                            >
                              Playbook
                            </Typography>
                          </TableCell>
                          {uId ? (
                            <TableCell align="center">
                              <Typography
                                variant={'caption'}
                                fontWeight={700}
                                sx={{ textTransform: 'uppercase' }}
                              >
                                Call Score
                              </Typography>
                            </TableCell>
                          ) : null}
                          <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                            >
                              Actions
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {analysisReviews.map((ar, i) => {
                          return (
                            <TableRow key={i}>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ minWidth: 150 }}
                              >
                                <Typography
                                  variant={'subtitle2'}
                                  fontWeight={700}
                                >
                                  {ar?.title}
                                </Typography>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                align="center"
                                // sx={{ maxWidth: 100 }}
                              >
                                {ar?.accountTagId ? (
                                  accountEditable?.id === ar?.id ? (
                                    <Box display={'flex'} gap={1}>
                                      <AutoCompleteMUI
                                        autoFocus
                                        defaultOpen
                                        id="accountName"
                                        value={accountName}
                                        onChange={(event, newValue) => {
                                          setAccountName(newValue);
                                        }}
                                        options={accounts?.map(
                                          (ac) => ac?.name,
                                        )}
                                        onTextChange={(e) =>
                                          setAccountName(e.target.value)
                                        }
                                        onKeyDown={() => {
                                          if (
                                            accountName &&
                                            accountName?.trim() &&
                                            accounts?.find(
                                              (ac) => ac?.name === accountName,
                                            )?.id !==
                                              accountEditable?.accountTagId
                                          ) {
                                            handleAccountUpdate(ar);
                                            setAccountEditable(false);
                                          } else {
                                            setAccountEditable(false);
                                          }
                                        }}
                                        placeholder="Buyer account name"
                                        minWidth={140}
                                      />
                                      <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        gap={0.5}
                                      >
                                        <Tooltip
                                          title="Close"
                                          arrow
                                          placement="right"
                                        >
                                          <IconButton
                                            sx={{
                                              borderRadius: 1,
                                              color: '#fff',
                                              width: 16,
                                              height: 16,
                                              background:
                                                theme.palette.text.secondary,
                                              ':hover': {
                                                backgroundColor:
                                                  theme.palette.text.secondary,
                                              },
                                            }}
                                            onClick={() => {
                                              setAccountEditable(false);
                                            }}
                                          >
                                            <Close sx={{ fontSize: 15 }} />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                          title="Done"
                                          arrow
                                          placement="right"
                                        >
                                          <IconButton
                                            sx={{
                                              borderRadius: 1,
                                              color: '#fff',
                                              width: 16,
                                              height: 16,
                                              background:
                                                theme.palette.primary.main,
                                              ':hover': {
                                                backgroundColor:
                                                  theme.palette.primary.main,
                                              },
                                            }}
                                            onClick={() => {
                                              if (
                                                accountName &&
                                                accountName?.trim() &&
                                                accounts?.find(
                                                  (ac) =>
                                                    ac?.name === accountName,
                                                )?.id !==
                                                  accountEditable?.accountTagId
                                              ) {
                                                handleAccountUpdate(ar);
                                                setAccountEditable(false);
                                              } else {
                                                setAccountEditable(false);
                                              }
                                            }}
                                          >
                                            <Check sx={{ fontSize: 15 }} />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    </Box>
                                  ) : (
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      title={
                                        'Click to update account association'
                                      }
                                    >
                                      <Typography
                                        variant={'subtitle2'}
                                        fontWeight={700}
                                        textAlign={'center'}
                                        sx={{
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          setAccountEditable(ar);
                                        }}
                                      >
                                        {accounts?.find(
                                          (ac) => ac?.id === ar?.accountTagId,
                                        )?.name || '-'}
                                      </Typography>
                                    </Tooltip>
                                  )
                                ) : (
                                  <Tooltip
                                    arrow
                                    placement="top"
                                    title={'Buyer Account Name'}
                                  >
                                    <BookmarkAdd
                                      // color="primary"
                                      sx={{
                                        cursor: 'pointer',
                                        color: '#677788',
                                      }}
                                      onClick={(e) => {
                                        if (anchorElAddAccount) {
                                          setAnchorElAddAccount(null);
                                          setAddAccountFor(null);
                                        } else {
                                          setAnchorElAddAccount(
                                            e.currentTarget,
                                          );
                                          setAddAccountFor(ar);
                                        }
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              </TableCell>
                              <TableCell>
                                <Typography variant={'caption'}>
                                  {moment(ar?.createdOn).format('DD MMMM YYYY')}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                  gap={1}
                                  sx={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => setShowPreviewSalesCall(ar)}
                                >
                                  <Typography variant={'subtitle2'}>
                                    {ar?.customData?.duration
                                      ? formatSeconds(ar?.customData?.duration)
                                      : '--:--'}
                                  </Typography>
                                  <IconButton
                                    sx={{
                                      background: '#d3d3d3',
                                      padding: '2px',
                                    }}
                                    onClick={() => setShowPreviewSalesCall(ar)}
                                  >
                                    <PlayArrow
                                      sx={{
                                        fontSize: 16,
                                      }}
                                    />
                                  </IconButton>
                                </Box>
                              </TableCell>
                              {/* {orgConfigs?.multiLanguageSupport ? (
                                <TableCell align="center">
                                  <Typography variant={'subtitle2'}>
                                    {ar?.languages?.join(', ') || 'English'}
                                  </Typography>
                                </TableCell>
                              ) : null} */}
                              <TableCell align="center">
                                <Typography variant={'subtitle2'}>
                                  {ar?.playbookTitle || '-'}
                                </Typography>
                              </TableCell>
                              {uId ? (
                                <TableCell
                                  align="center"
                                  onMouseEnter={(e) => {
                                    setAnchorElDetails(e.currentTarget);
                                    setPopperDetails(
                                      ar?.analysis?.methodAnalysis
                                        ?.contentScoring?.scores,
                                    );
                                  }}
                                  onMouseLeave={() => {
                                    setAnchorElDetails(null);
                                    setPopperDetails(null);
                                  }}
                                >
                                  {ar?.analysis?.methodAnalysis?.contentScoring
                                    ?.scores?.overall ? (
                                    <ProfileCompletion
                                      percentCompleted={Math.round(
                                        ar?.analysis?.methodAnalysis
                                          ?.contentScoring?.scores?.overall,
                                      )}
                                      circleSize={45}
                                      circleTextSize={12}
                                      fontSize={12}
                                      thickness={5}
                                      dontShowExtraText={true}
                                    />
                                  ) : (
                                    <Typography variant={'subtitle2'}>
                                      -
                                    </Typography>
                                  )}
                                  <Popper
                                    id="mouse-over-popover"
                                    sx={{
                                      pointerEvents: 'none',
                                      zIndex: 1111111,
                                    }}
                                    open={Boolean(anchorElDetails)}
                                    anchorEl={anchorElDetails}
                                    placement="bottom"
                                    onClose={() => {
                                      setAnchorElDetails(null);
                                    }}
                                    // disableRestoreFocus
                                  >
                                    <Box
                                      sx={{
                                        border: '1px solid #d3d3d3',
                                        borderRadius: 1,
                                        padding: 1,
                                        background: '#fff',
                                        maxWidth: 400,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1,
                                        boxShadow: theme.shadows[1],
                                      }}
                                    >
                                      {Object.keys(popperDetails || {})
                                        ?.filter((key) => key !== 'overall')
                                        ?.map((scoreKey) => (
                                          <Box
                                            display={'flex'}
                                            alignItems={'center'}
                                            key={scoreKey}
                                            justifyContent={'space-between'}
                                            padding={1}
                                            gap={2}
                                          >
                                            <Typography>{scoreKey}</Typography>
                                            <ProfileCompletion
                                              percentCompleted={Math.round(
                                                popperDetails?.[scoreKey],
                                              )}
                                              circleSize={45}
                                              circleTextSize={12}
                                              fontSize={12}
                                              thickness={5}
                                              dontShowExtraText={true}
                                            />
                                          </Box>
                                        ))}
                                    </Box>
                                  </Popper>
                                </TableCell>
                              ) : null}
                              <TableCell align="center">
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  gap={1}
                                  justifyContent={'center'}
                                >
                                  <Tooltip
                                    title={
                                      ar?.analysis
                                        ? 'View Report'
                                        : analysisInProgress.includes(ar?.id)
                                        ? 'Analysis is in progress'
                                        : ar.status === 'failed'
                                        ? `Failed to generate transcript.${
                                            uId ? '' : ' Try analyzing Again'
                                          }`
                                        : ar?.status === 'pending' ||
                                          ar?.status === 'in_process'
                                        ? `Transcript generation is still in progress.`
                                        : ar?.status === 'success'
                                        ? ar?.analysisStatus === 'in_process'
                                          ? 'Analysis is in progress'
                                          : ar?.analysisStatus === 'completed'
                                          ? 'View Report'
                                          : ar?.analysisStatus === 'failed'
                                          ? `Failed to analyse call.${
                                              uId
                                                ? ''
                                                : ' Please analyse again!'
                                            }`
                                          : ar?.analysisStatus === null
                                          ? `Transcript generated.${
                                              uId ? '' : '  Analyze'
                                            }`
                                          : '-'
                                        : ar?.status === 'created'
                                        ? 'Analyze Call'
                                        : '-'
                                    }
                                    placement="top"
                                    arrow
                                  >
                                    <IconButton
                                      onClick={(e) => {
                                        if (ar?.analysis) {
                                          // console.log('view report');
                                          handleTrackEvent(
                                            e,
                                            'view_analysis_report',
                                          );
                                          setViewType('report');
                                          setSaleReviewId(ar?.id);
                                          setSalesReview(ar);
                                          if (uId) {
                                            dispatch({
                                              type: 'UPDATE_UI_STATE',
                                              payload: {
                                                key: 'currentNavigationLevel',
                                                value:
                                                  currentNavigationLevel + 1,
                                              },
                                            });
                                          }
                                        } else {
                                          if (!uId) {
                                            if (ar?.transcript) {
                                              handleTrackEvent(
                                                e,
                                                'open_reanalyze_dialog',
                                              );
                                              setShowReAnalyzeDrawer(ar?.id);
                                              setSalesReview(ar);
                                            } else {
                                              if (ar?.status === 'failed') {
                                                setShowTranscriptionFailed(ar);
                                              } else if (
                                                ar?.status === 'pending' ||
                                                ar?.status === 'in_process'
                                              ) {
                                                setShowTranscriptionFailed(ar);
                                              } else if (
                                                ar?.status === 'created'
                                              ) {
                                                setShowTranscriptionFailed(ar);
                                              } else {
                                                return;
                                              }
                                            }
                                          }
                                        }
                                      }}
                                    >
                                      {ar?.analysis ||
                                      ar?.analysisStatus === 'completed' ? (
                                        <Article
                                          color={
                                            ar?.analysis ? 'primary' : 'default'
                                          }
                                        />
                                      ) : ar?.status === 'failed' ||
                                        ar?.analysisStatus === 'failed' ? (
                                        <WarningAmber
                                          sx={{
                                            color: theme.palette.secondary.main,
                                          }}
                                        />
                                      ) : ar?.status === 'success' &&
                                        ar?.analysisStatus === null ? (
                                        <WarningAmber
                                          sx={{
                                            color: theme.palette.secondary.main,
                                          }}
                                        />
                                      ) : ar?.status === 'pending' ||
                                        ar?.status === 'in_process' ||
                                        ar?.analysisStatus === 'in_process' ? (
                                        <Cached
                                          sx={{
                                            color: theme.palette.secondary.main,
                                          }}
                                        />
                                      ) : ar?.status === 'created' ? (
                                        <Troubleshoot
                                          sx={{
                                            color: theme.palette.primary.main,
                                          }}
                                        />
                                      ) : null}
                                    </IconButton>
                                  </Tooltip>
                                  {Object.keys(
                                    userDetails?.plan?.features || {},
                                  )?.find((feature) => feature === 'task') ? (
                                    <Tooltip
                                      title={
                                        (ar?.counts?.totalTask || 0) -
                                          (ar?.counts?.completedTask || 0) >
                                          0 || ar?.counts?.totalTask > 0
                                          ? 'Open tasks associated with the sales call'
                                          : 'No associated tasks found'
                                      }
                                      arrow
                                      placement="top"
                                    >
                                      <IconButton
                                        onClick={() => {
                                          if (
                                            (ar?.counts?.totalTask || 0) -
                                              (ar?.counts?.completedTask || 0) >
                                              0 ||
                                            ar?.counts?.totalTask > 0
                                          ) {
                                            history.push({
                                              pathname: '/tasks',
                                              state: {
                                                id: ar?.id,
                                                type: 'saleReviewAnalysis',
                                                title: ar?.title,
                                              },
                                            });
                                          }
                                        }}
                                      >
                                        <Badge
                                          badgeContent={
                                            (ar?.counts?.totalTask || 0) -
                                            (ar?.counts?.completedTask || 0)
                                          }
                                          sx={{
                                            '& .MuiBadge-badge': {
                                              border: '1px solid #d3d3d3',
                                              background: '#fff',
                                              // color: '#fff',
                                              fontSize: '8px',
                                              fontWeight: 'bold',
                                              padding: '0px',
                                            },
                                          }}
                                        >
                                          <TaskAlt
                                            sx={{
                                              color:
                                                (ar?.counts?.totalTask || 0) -
                                                  (ar?.counts?.completedTask ||
                                                    0) >
                                                0
                                                  ? theme.palette.primary.main
                                                  : theme.palette.grey[400],
                                            }}
                                          />
                                        </Badge>
                                      </IconButton>
                                    </Tooltip>
                                  ) : null}
                                  {Object.keys(
                                    userDetails?.plan?.features || {},
                                  )?.find((feature) => feature === 'donna') ? (
                                    <Tooltip
                                      title={
                                        ar?.analysis
                                          ? 'Ask Donna'
                                          : analysisInProgress.includes(ar?.id)
                                          ? 'Donna will be available once analysis is ready'
                                          : ar.status === 'failed'
                                          ? `Failed to generate transcript.${
                                              uId
                                                ? ''
                                                : ' Call Analysis Pending'
                                            }`
                                          : ar?.status === 'pending' ||
                                            ar?.status === 'in_process' ||
                                            ar?.status === 'created'
                                          ? `Donna will be available once analysis is ready`
                                          : ar?.status === 'success'
                                          ? ar?.analysisStatus === 'in_process'
                                            ? 'Donna will be available once analysis is ready'
                                            : ar?.analysisStatus === 'completed'
                                            ? 'Ask Donna'
                                            : ar?.analysisStatus === 'failed'
                                            ? `Failed to analyse call.${
                                                uId
                                                  ? ''
                                                  : ' Please analyse again!'
                                              }`
                                            : ar?.analysisStatus === null
                                            ? `Donna will be available once analysis is ready`
                                            : '-'
                                          : '-'
                                      }
                                      arrow
                                      placement="top"
                                    >
                                      <IconButton
                                        onClick={() => {
                                          if (
                                            ar?.analysis ||
                                            ar?.analysisStatus === 'completed'
                                          ) {
                                            setShowAskDonna(ar);
                                          }
                                        }}
                                      >
                                        <AutoAwesome
                                          sx={{
                                            color:
                                              ar?.analysis ||
                                              ar?.analysisStatus === 'completed'
                                                ? theme.palette.primary.main
                                                : theme.palette.grey[500],
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  ) : null}
                                  {userDetails?.custom?.integration?.hubSpot &&
                                  (process.env.REACT_APP_TARGET_ENV ===
                                    'local' ||
                                    process.env.REACT_APP_TARGET_ENV ===
                                      'development') ? (
                                    <Tooltip
                                      title={'Sync Hubspot Properties'}
                                      placement="top"
                                      arrow
                                    >
                                      <IconButton
                                        onClick={(e) => {
                                          setShowSyncHubspotPropertiesDialog(
                                            ar,
                                          );
                                        }}
                                      >
                                        <img
                                          src="/hubspot-icon.webp"
                                          width={'20px'}
                                          height={'20px'}
                                          // style={{
                                          //   filter: member?.custom
                                          //     ?.hubspotUser
                                          //     ? 'none'
                                          //     : 'grayscale(100%)',
                                          // }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  ) : null}
                                  {!uId ? (
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      title={'Delete Call'}
                                    >
                                      <IconButton
                                        onClick={(e) => {
                                          setShowConfirmDeleteDialog(ar?.id);
                                        }}
                                      >
                                        <Delete
                                          sx={{
                                            color: '#a3a3a3',
                                            fontSize: 20,
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  ) : null}
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell
                            variant="footer"
                            colSpan={6}
                            sx={{
                              padding: 0,
                            }}
                          >
                            {fetchingPagination ? (
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  padding: 1,
                                  textAlign: 'center',
                                }}
                              >
                                <Button
                                  size="small"
                                  variant="contained"
                                  // type="submit"
                                  color="primary"
                                  onClick={handleListScroll}
                                  // className="searchSettings"
                                  disabled
                                  // fullWidth
                                >
                                  <Typography color="textPrimary">
                                    Loading ...{' '}
                                  </Typography>
                                  <CircularProgress size={20} />
                                </Button>
                              </Box>
                            ) : emptyList === false &&
                              analysisReviews.length >= 10 ? (
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  padding: 1,
                                  textAlign: 'center',
                                }}
                              >
                                <Button
                                  size="small"
                                  variant="contained"
                                  color="primary"
                                  onClick={(e) => handleListScroll(e, true)}
                                >
                                  Load More
                                </Button>
                              </Box>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                  <Popper
                    open={openMenu}
                    anchorEl={anchorEl}
                    sx={{
                      zIndex: 1300,
                    }}
                    placement={'bottom-end'}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        border: '1px solid #d3d3d3',
                        borderRadius: 1,
                        padding: 1,
                        background: '#fff',
                        // minWidth: 300,
                      }}
                    >
                      <FormControl size="small" sx={{ minWidth: 120 }}>
                        <InputLabel id="priority-select">Account</InputLabel>
                        <Select
                          size="small"
                          labelId="priority-select-label"
                          id="priority-select"
                          name="priorityType"
                          label="Account"
                          MenuProps={{ disableScrollLock: true }}
                          onChange={(e) => setActiveAccount(e.target.value)}
                          value={activeAccount}
                        >
                          {[{ id: activeUUID, name: 'all' }, ...accounts]?.map(
                            (account) => (
                              <MenuItem value={account?.id} key={account?.id}>
                                <Typography variant="caption">
                                  {account?.name}
                                </Typography>
                              </MenuItem>
                            ),
                          )}
                        </Select>
                      </FormControl>
                      <Box display={'flex'} gap={1}>
                        <Tooltip arrow placement="top" title={'Close'}>
                          <IconButton
                            sx={{
                              borderRadius: 1,
                              border: '1px solid #d3d3d3',
                              width: 40,
                              height: 40,
                            }}
                            onClick={() => {
                              setAnchorEl(null);
                            }}
                          >
                            <Close sx={{ fontSize: 18 }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="top" title={'Reset'}>
                          <IconButton
                            sx={{
                              borderRadius: 1,
                              border: `1px solid ${theme.palette.primary.main}`,
                              width: 40,
                              height: 40,
                            }}
                            onClick={() => {
                              setAnchorEl(null);
                              resetSearch(true);
                            }}
                          >
                            <Replay sx={{ fontSize: 18 }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="top" title={'Filter'}>
                          <IconButton
                            sx={{
                              borderRadius: 1,
                              color: '#fff',
                              width: 40,
                              height: 40,
                              background: theme.palette.primary.main,
                              ':hover': {
                                backgroundColor: theme.palette.primary.main,
                              },
                            }}
                            onClick={() => {
                              setAnchorEl(null);
                              handleAccountFilter();
                            }}
                          >
                            <Check sx={{ fontSize: 18 }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Popper>
                  <Popper
                    open={openAddAccountMenu}
                    anchorEl={anchorElAddAccount}
                    sx={{
                      zIndex: 1300,
                    }}
                    placement={'bottom'}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        border: '1px solid #d3d3d3',
                        borderRadius: 1,
                        padding: 1,
                        background: '#fff',
                        // minWidth: 300,
                      }}
                    >
                      <FormControl size="small" sx={{ minWidth: 120 }}>
                        <AutoCompleteMUI
                          autoFocus
                          defaultOpen
                          type="account"
                          id="accountName"
                          value={accountName}
                          onChange={(event, newValue) => {
                            setAccountName(newValue);
                          }}
                          options={accounts?.map((ac) => ac?.name)}
                          onTextChange={(e) => setAccountName(e.target.value)}
                          onKeyDown={() => {}}
                          placeholder="Buyer account name"
                        />
                      </FormControl>
                      <Box display={'flex'} flexDirection={'column'} gap={0.5}>
                        <Tooltip title="Close" arrow placement="right">
                          <IconButton
                            sx={{
                              borderRadius: 1,
                              color: '#fff',
                              width: 16,
                              height: 16,
                              background: theme.palette.text.secondary,
                              ':hover': {
                                backgroundColor: theme.palette.text.secondary,
                              },
                            }}
                            onClick={() => {
                              setAnchorElAddAccount(null);
                              setAddAccountFor(null);
                            }}
                          >
                            <Close sx={{ fontSize: 15 }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Done" arrow placement="right">
                          <IconButton
                            sx={{
                              borderRadius: 1,
                              color: '#fff',
                              width: 16,
                              height: 16,
                              background: theme.palette.primary.main,
                              ':hover': {
                                backgroundColor: theme.palette.primary.main,
                              },
                            }}
                            onClick={() => {
                              handleAccountUpdate();
                            }}
                          >
                            <Check sx={{ fontSize: 15 }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Popper>
                </Box>
              ) : (
                <Box
                  width={1}
                  height={1}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'column'}
                  gap={2}
                >
                  <Typography variant="body1" color={'text.secondary'} mt={4}>
                    No recordings to show here yet.
                  </Typography>
                  {!uId && !(searchKeywords && searchKeywords.length > 0) ? (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      // sx={{ marginLeft: 'auto' }}
                      onClick={() =>
                        history.push(`sales-call-analysis?new=true`)
                      }
                    >
                      Upload Recording
                    </Button>
                  ) : null}
                </Box>
              )}
            </Box>
          ) : viewType === 'report' ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                width: 1,
                paddingTop: uId ? 0 : 2,
              }}
            >
              <ReportPage
                type="salesCall"
                report={salesReview}
                setSalesReview={setSalesReview}
                handleReanalyze={handleAnalyze}
                searchKeywords={searchKeywords}
                searchKeywordsMap={searchKeywordsMap}
                setShowReAnalyzeDrawer={setShowReAnalyzeDrawer}
                uId={uId}
              />
            </Box>
          ) : null}
        </Box>
        <Box
          flex={{ xs: 'auto', sm: 'auto', md: 1 }}
          sx={{
            position: 'relative',
            display:
              currentTab === 'create' && viewType !== 'report'
                ? 'flex'
                : 'none',
            alignItems: 'stretch',
            minHeight: 'calc(100vh - 60px)',
            maxHeight: 'calc(100vh - 60px)',
          }}
        >
          {currentTab === 'create' ? (
            <Box
              width={1}
              display={'flex'}
              alignItems={activeStep === 0 ? 'center' : 'stretch'}
              flexDirection={'column'}
              justifyContent={'space-between'}
            >
              {uploadingCall ? (
                <Box
                  sx={{
                    padding: 2,
                    width: 1,
                    height: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box position={'relative'}>
                    <CircularProgress
                      variant="determinate"
                      size={200}
                      value={progressValue}
                      thickness={4}
                    />
                    <Typography
                      variant="body1"
                      color={theme.palette.text.primary}
                      textAlign={'center'}
                      sx={{
                        fontSize: 26,
                        fontWeight: 'bold',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      {progressValue} %
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    color={theme.palette.text.primary}
                    textAlign={'center'}
                    sx={{ fontSize: 20, marginTop: 10 }}
                  >
                    Uploading. Please wait ...
                  </Typography>
                </Box>
              ) : activeStep === 0 ? (
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  flex={1}
                >
                  <InputLabel
                    htmlFor="audioCall"
                    style={{ cursor: 'pointer', padding: 24 }}
                  >
                    <input
                      style={{ display: 'none' }}
                      id="audioCall"
                      ref={inputImage}
                      type="file"
                      accept="audio/*,video/*"
                      onChange={(e) => {
                        if (
                          userDetails?.plan?.trailEndsOn >
                            new Date().getTime() ||
                          userDetails?.plan?.currentPaymentStatus === 'paid'
                        ) {
                          onFileChange(e);
                        } else {
                          dispatch({
                            type: 'UPDATE_UI_STATE',
                            payload: {
                              key: 'showPlanExpired',
                              value: true,
                            },
                          });
                        }
                      }}
                    />
                    <Box
                      sx={{
                        border: '1px dashed #d3d3d3',
                        borderRadius: 4,
                        width: 350,
                        minHeight: 400,
                        p: 2,
                        textAlign: 'center',
                      }}
                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      gap={2}
                    >
                      <CloudUpload
                        sx={{
                          fontSize: 40,
                          color: theme.palette.primary.main,
                        }}
                      />
                      {selectedAudio ? (
                        <Typography
                          color={'#677788'}
                          sx={{ whiteSpace: 'wrap' }}
                        >
                          {selectedAudio?.name}
                        </Typography>
                      ) : null}
                      <Box
                        sx={{
                          background: theme.palette.primary.main,
                          borderRadius: 1,
                          padding: '8px 16px',
                        }}
                      >
                        <Typography color={'#fff'}>
                          {selectedAudio
                            ? 'Change Recording File'
                            : 'Choose Recording File'}
                        </Typography>
                      </Box>
                      <Box display={'flex'} flexDirection={'column'} gap={1}>
                        {invalidFile ? (
                          <Alert severity="error">
                            <Typography
                              variant="body1"
                              sx={{
                                whiteSpace: 'pre-wrap',
                                color: theme.palette.error.main,
                              }}
                            >
                              {invalidFile}
                            </Typography>
                          </Alert>
                        ) : null}
                        <Typography
                          variant="caption"
                          sx={{
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          (Common audio/video formats supported)
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          Upload upto{' '}
                          {Math.round(
                            Object.values(
                              Object.values(
                                userDetails?.custom?.plan?.features || {},
                              )?.find((obj) => obj?.feature === 'call_analysis')
                                ?.restrictions || {},
                            )?.find(
                              (obj) =>
                                obj?.restriction ===
                                'duration_of_sale_call_recording_analysis',
                            )?.limit /
                              (1000 * 60),
                          )}{' '}
                          minutes duration of recording
                        </Typography>
                      </Box>
                    </Box>
                  </InputLabel>
                </Box>
              ) : activeStep === 1 ? (
                <Box
                  p={2}
                  display={'flex'}
                  flexDirection={'column'}
                  gap={2}
                  flex={1}
                >
                  <Box>
                    <Typography variant="subtitle2" color={'text.secondary'}>
                      Account Name (Associate it with an account and filter
                      later)(Optional)
                    </Typography>
                    <AutoCompleteMUI
                      autoFocus
                      id="accountName"
                      value={accountName}
                      onChange={(event, newValue) => {
                        setAccountName(newValue);
                      }}
                      options={accounts?.map((ac) => ac?.name)}
                      onTextChange={(e) => setAccountName(e.target.value)}
                      onKeyDown={() => {}}
                      placeholder="Buyer account name"
                    />
                    {/* <TextField
                      autoFocus
                      placeholder="Account Name"
                      variant="outlined"
                      color="primary"
                      size="small"
                      name="accountName"
                      value={accountName}
                      onChange={(e) => setAccountName(e.target.value)}
                      fullWidth
                    /> */}
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" color={'text.secondary'}>
                      Title
                    </Typography>
                    <TextField
                      autoFocus
                      placeholder="Title"
                      variant="outlined"
                      color="primary"
                      size="small"
                      name="title"
                      value={conversationTitle}
                      onChange={(e) => setConversationTitle(e.target.value)}
                      fullWidth
                    />
                  </Box>
                  <Box>
                    {userInfo?.orgRoles?.includes('ORG_ADMIN') ||
                    userInfo?.teamRoles?.includes('TEAM_ADMIN') ? (
                      <FormControl
                        component="fieldset"
                        variant="standard"
                        // sx={{ marginTop: 2 }}
                      >
                        <FormLabel component="legend" sx={{ fontSize: '14px' }}>
                          Methodologies
                        </FormLabel>
                        <FormGroup row>
                          {orgConfigs?.selectedMethodologies?.map((mthd) => (
                            <FormControlLabel
                              key={mthd}
                              checked={methods?.includes(mthd)}
                              control={
                                <Checkbox
                                  size="small"
                                  value={mthd}
                                  name="methods"
                                />
                              }
                              label={
                                mthd === 'meddpic'
                                  ? 'MEDDICC'
                                  : mthd.toUpperCase()
                              }
                              onChange={(e) => handleChange(e, true)}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    ) : (
                      <>
                        <Typography
                          variant={'body1'}
                          // sx={{ marginTop: 1 }}
                          color={'text.secondary'}
                          // fontWeight={'bold'}
                        >
                          Methodologies
                        </Typography>
                        <Typography
                          variant={'body1'}
                          // sx={{ marginBottom: 1 }}
                          // color={'text.secondary'}
                          // fontWeight={'bold'}
                        >
                          {teamId
                            ? loggedInUserTeamDetails?.selectedMethodologies
                                ?.map((mthd) =>
                                  mthd === 'meddpic'
                                    ? 'MEDDICC'
                                    : capitalizeText(mthd),
                                )
                                ?.join(', ')
                            : orgConfigs?.selectedMethodologies
                                ?.map((mthd) =>
                                  mthd === 'meddpic'
                                    ? 'MEDDICC'
                                    : capitalizeText(mthd),
                                )
                                ?.join(', ')}
                        </Typography>
                      </>
                    )}
                  </Box>
                  {teamId ? (
                    loggedInUserTeamDetails?.languagesForAnalysis?.length >
                    1 ? (
                      <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend" sx={{ fontSize: '14px' }}>
                          Languages
                        </FormLabel>
                        <FormGroup row>
                          <FormControlLabel
                            checked={languages?.includes('english')}
                            control={
                              <Checkbox
                                size="small"
                                value={'english'}
                                name="languages"
                              />
                            }
                            label={'English'}
                            onChange={(e) => handleLanguageChange(e)}
                          />
                          <FormControlLabel
                            checked={languages?.includes('hindi')}
                            control={
                              <Checkbox
                                size="small"
                                value={'hindi'}
                                name="languages"
                              />
                            }
                            label={'Hindi'}
                            onChange={(e) => handleLanguageChange(e)}
                          />
                        </FormGroup>
                      </FormControl>
                    ) : null
                  ) : orgConfigs?.languagesForAnalysis?.length > 1 &&
                    userDetails?.userSetting?.languagesForAnalysis?.length >
                      1 ? (
                    <FormControl component="fieldset" variant="standard">
                      <FormLabel component="legend" sx={{ fontSize: '14px' }}>
                        Languages
                      </FormLabel>
                      <FormGroup row>
                        <FormControlLabel
                          checked={languages?.includes('english')}
                          control={
                            <Checkbox
                              size="small"
                              value={'english'}
                              name="languages"
                            />
                          }
                          label={'English'}
                          onChange={(e) => handleLanguageChange(e)}
                        />
                        <FormControlLabel
                          checked={languages?.includes('hindi')}
                          control={
                            <Checkbox
                              size="small"
                              value={'hindi'}
                              name="languages"
                            />
                          }
                          label={'Hindi'}
                          onChange={(e) => handleLanguageChange(e)}
                        />
                      </FormGroup>
                    </FormControl>
                  ) : null}
                  <Box>
                    {/* {userInfo?.email ===
                    'demo@qualification.ai' ? null : teamId ? null : (
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Call Type
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={salesCallType}
                          onChange={(e) => setSalesCallType(e.target.value)}
                        >
                          <FormControlLabel
                            value={'outbound'}
                            control={<Radio />}
                            label={'Outbound'}
                          />
                          <FormControlLabel
                            value={'inbound'}
                            control={<Radio />}
                            label={'Inbound'}
                          />
                        </RadioGroup>
                      </FormControl>
                    )} */}
                    {userInfo?.orgRoles?.includes('ORG_ADMIN') ||
                    userInfo?.teamRoles?.includes('TEAM_ADMIN') ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          color={'text.secondary'}
                        >
                          Select from available playbooks
                        </Typography>
                        <FormControl fullWidth>
                          <Select
                            size="small"
                            labelId="playbook-select-label"
                            id="playbook-select"
                            name="selectedPlaybook"
                            MenuProps={{ disableScrollLock: true }}
                            onChange={(e) =>
                              setSelectedPlaybook(e.target.value)
                            }
                            value={selectedPlaybook}
                          >
                            <MenuItem value={'select'}>
                              <Typography>
                                Select from available playbooks
                              </Typography>
                            </MenuItem>
                            {playbooks && playbooks?.length > 0
                              ? playbooks?.map((pb) => (
                                  <MenuItem key={pb?.id} value={pb?.id}>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        gap: 2,
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <Typography>
                                        {pb?.tags?.join(', ')}{' '}
                                      </Typography>
                                      <Typography>
                                        {moment(pb?.createdOn).format(
                                          'DD MMM YYYY',
                                        )}
                                        {pb?.selected ? '(Default)' : ''}
                                      </Typography>
                                    </Box>
                                  </MenuItem>
                                ))
                              : null}
                          </Select>
                        </FormControl>
                      </>
                    ) : null}
                  </Box>
                  {(orgConfigs?.showPlaybookToManagersOnly &&
                    (userInfo?.orgRoles?.includes('ORG_ADMIN') ||
                      userInfo?.orgRoles?.includes('WL_ORG_ADMIN'))) ||
                  !orgConfigs?.showPlaybookToManagersOnly ? (
                    <Box
                      sx={{
                        flexBasis: '100%',
                      }}
                    >
                      <Typography variant="subtitle2" color={'text.secondary'}>
                        Playbook
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          whiteSpace: 'pre-wrap',
                          flex: 1,
                          // borderRight: '1px solid #d3d3d3',
                          border: '1px solid #d3d3d3',
                          overflowY: 'scroll',
                          maxHeight: userInfo?.orgRoles?.includes('ORG_ADMIN')
                            ? 220
                            : 300,
                          borderRadius: 2,
                          '&::-webkit-scrollbar': {
                            width: '5px',
                          },
                          '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: 2,
                          },
                          p: 2,
                        }}
                      >
                        {parse(playbook)}
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              ) : (
                <Box
                  gap={2}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={
                    transcriptReady && salesReview ? 'stretch' : 'center'
                  }
                  justifyContent={
                    transcriptReady && salesReview ? 'flex-start' : 'center'
                  }
                  // flex={1}
                  sx={{
                    // width: 1,
                    padding: 2,
                    minHeight: 'calc(100vh - 100px)',
                    maxHeight: 'calc(100vh - 100px)',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                    },
                  }}
                >
                  {transcriptReady && salesReview ? (
                    <Box display={'flex'} flexDirection={'column'} gap={2}>
                      <Box
                        sx={{
                          borderRadius: 3,
                          border: '1px solid #e3e3e3',
                        }}
                      >
                        <Box sx={{ background: '#e3e3e3', padding: 2 }}>
                          <Typography
                            color={'text.secondary'}
                            fontWeight={'bold'}
                          >
                            Transcript
                          </Typography>
                        </Box>
                        <Typography
                          variant="body1"
                          sx={{
                            minHeight: '260px',
                            maxHeight: '260px',
                            overflowY: 'scroll',
                            '&::-webkit-scrollbar': {
                              width: '5px',
                            },
                            '&::-webkit-scrollbar-track': {
                              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: theme.palette.primary.main,
                              borderRadius: 2,
                            },
                            whiteSpace: 'pre-wrap',
                            padding: 2,
                          }}
                        >
                          {salesReview?.transcript}
                        </Typography>
                      </Box>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Who is the salesperson?
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={speaker}
                          onChange={(e) => setSpeaker(e.target.value)}
                        >
                          {speakers &&
                            Object.keys(speakers)
                              ?.sort()
                              ?.map((spk) => (
                                <FormControlLabel
                                  key={spk}
                                  value={spk}
                                  control={<Radio />}
                                  label={speakers?.[spk]}
                                />
                              ))}
                        </RadioGroup>
                      </FormControl>
                      <FormLabel id="speakers-labels">Speakers</FormLabel>
                      {speakers &&
                      Object.keys(speakers) &&
                      Object.keys(speakers).length > 0
                        ? Object.keys(speakers)
                            .sort()
                            .map((spk) => (
                              <Grid container spacing={1} key={spk}>
                                <Grid item xs={1}>
                                  <Typography flex={1}>{spk}</Typography>
                                </Grid>
                                <Grid item xs={11}>
                                  <TextField
                                    flex={2}
                                    autoFocus
                                    placeholder={`Name`}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    name={`${spk}_name`}
                                    value={speakers?.[spk]}
                                    onChange={(e) => {
                                      const updatedSpeakes = {
                                        ...speakers,
                                        [spk]: e.target.value,
                                      };
                                      setSpeakers(updatedSpeakes);
                                    }}
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            ))
                        : null}
                      {(orgConfigs?.showPlaybookToManagersOnly &&
                        (userInfo?.orgRoles?.includes('ORG_ADMIN') ||
                          userInfo?.orgRoles?.includes('WL_ORG_ADMIN'))) ||
                      !orgConfigs?.showPlaybookToManagersOnly ? (
                        <Box
                          sx={{
                            borderRadius: 3,
                            border: '1px solid #e3e3e3',
                          }}
                        >
                          <Box
                            sx={{
                              background: '#e3e3e3',
                              padding: 2,
                              borderTopLeftRadius: 3,
                              borderTopRightRadius: 3,
                            }}
                          >
                            <Typography
                              color={'text.secondary'}
                              fontWeight={'bold'}
                            >
                              Playbook
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              // minHeight: '260px',
                              maxHeight: '260px',
                              overflowY: 'scroll',
                              '&::-webkit-scrollbar': {
                                width: '5px',
                              },
                              '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow:
                                  'inset 0 0 6px rgba(0,0,0,0.00)',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: 2,
                              },
                              whiteSpace: 'pre-wrap',
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{
                                whiteSpace: 'pre-wrap',
                                flex: 1,
                                // borderRight: '1px solid #d3d3d3',
                                p: 2,
                              }}
                            >
                              {parse(playbook)}
                            </Typography>
                          </Box>
                        </Box>
                      ) : null}
                    </Box>
                  ) : transcriptionFailed ? (
                    <Box
                      sx={{
                        border: '1px solid #f1f1f1',
                        borderRadius: 2,
                        display: 'flex',
                        padding: '32px 16px',
                        flexDirection: 'column',
                        gap: 2,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="body1">
                        Failed to generate transcript. Please try uploading the
                        recording again.
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        border: '1px solid #f1f1f1',
                        borderRadius: 2,
                        display: 'flex',
                        padding: '32px 16px',
                        flexDirection: 'column',
                        gap: 2,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CircularProgress
                        size={30}
                        sx={{ color: theme.palette.primary.main }}
                      />
                      <Typography variant="body1">
                        Please wait while we generate the transcript ...
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                p={2}
                width={1}
                borderTop={'1px solid #f1f1f1'}
              >
                {activeStep !== 2 ? (
                  <Button
                    variant="outlined"
                    size="small"
                    disabled={activeStep === 0}
                    onClick={(e) => {
                      handleTrackEvent(e, 'sales_call_analysis_prev_clicked');
                      // setActiveStep((prev) => prev - 1);
                      resetThread();
                    }}
                  >
                    Previous
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    size="small"
                    // disabled={activeStep === 0}
                    onClick={(e) => {
                      handleTrackEvent(
                        e,
                        'sales_call_analysis_restart_clicked',
                      );
                      resetThread();
                    }}
                  >
                    Restart
                  </Button>
                )}
                <Tooltip
                  title={
                    activeStep === 1 && !conversationTitle?.trim()
                      ? 'Title is mandatory'
                      : ''
                  }
                  arrow
                  placement="top"
                >
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      backgroundColor:
                        activeStep === 1 && !conversationTitle?.trim()
                          ? theme.palette.background.default
                          : theme.palette.primary.main,
                      color:
                        activeStep === 1 && !conversationTitle?.trim()
                          ? '#999'
                          : '#fff',
                    }}
                    disabled={
                      activeStep === 0
                        ? selectedAudio && !invalidFile
                          ? false
                          : true
                        : activeStep === 2
                        ? analysisInProgress.includes(saleReviewId)
                          ? true
                          : transcriptReady
                          ? false
                          : true
                        : uploadingCall
                        ? true
                        : false
                    }
                    onClick={(e) => {
                      handleTrackEvent(
                        e,
                        activeStep === steps?.length - 1
                          ? 'sales_call_analysis_next_step'
                          : 'analyze',
                      );
                      if (activeStep === 0) {
                        setActiveStep((prev) => prev + 1);
                      } else if (
                        activeStep === 1 &&
                        conversationTitle?.trim()
                      ) {
                        handleUpload();
                      } else if (activeStep === 2) {
                        handleAnalyze(saleReviewId, 'create', salesReview);
                      }
                    }}
                  >
                    {activeStep === steps?.length - 1 ? 'Analyze' : 'Next'}
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
      <Modal
        open={Boolean(showPreviewSalesCall)}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
            setShowPreviewSalesCall(null);
          else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            // maxHeight: '30vh',
            maxWidth: '50vw',
            minWidth: '300px',
            outline: 'none',
            borderRadius: 1,
            // padding: 3,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 1,
              justifyContent: 'space-between',
              padding: '4px 16px',
            }}
          >
            <Typography variant="body2">Media</Typography>
            <IconButton
              data-trackid={'close_preview_sales_call'}
              onClick={(e) => {
                handleTrackEvent(e, 'close_preview_sales_call');
                setShowPreviewSalesCall(null);
              }}
              aria-label="close"
            >
              <Close style={{ fontSize: 20 }} />
            </IconButton>
          </Box>
          <Divider sx={{ width: 1 }} />
          <Box p={1}>
            <MediaPreview
              styles={{
                flex: 1,
              }}
              url={showPreviewSalesCall?.customData?.url || ''}
              mediaType={showPreviewSalesCall?.saleReviewMediaContentType || ''}
              setDuration={(duration) => {
                if (!showPreviewSalesCall?.duration) {
                  dispatch({
                    type: 'UPDATE_SALES_CALL_ANALYSIS',
                    payload: {
                      id: showPreviewSalesCall?.id,
                      data: {
                        ...showPreviewSalesCall,
                        customData: {
                          ...(showPreviewSalesCall.customData || {}),
                          duration: Math.round(duration),
                        },
                      },
                    },
                  });
                }
              }}
            />
          </Box>
          {/* <Box
              sx={{
                width: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              mt={2}
            >
              <Button
                data-trackid={'send_calendar_event_to_email'}
                variant="text"
                color="primary"
                onClick={(e) => {
                  handleTrackEvent(e, 'send_calendar_event_to_email');
                  handleCalendarActions('email');
                }}
              >
                Send me via email
              </Button>
              <Button
                variant="contained"
                data-trackid={'download_event_ics_file'}
                color="primary"
                onClick={(e) => {
                  handleTrackEvent(e, 'send_calendar_event_to_email');
                  handleCalendarActions('download');
                }}
              >
                Download .ics
              </Button>
            </Box> */}
        </Box>
      </Modal>
      <Dialog
        open={Boolean(showLimitExhausted)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Sorry, your plan&apos;s limit has been exhausted.
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Your current plan has reached its usage limit for this feature. To
              continue, consider upgrading your plan or wait until the limit
              resets at the beginning of the next cycle. If you have any
              questions or need assistance, please contact our support team.
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                // handleTrackEvent(e, `cta_permission_denied_dialog_close`);

                history.push('/pricing');
                // if (testMode) setShowTestDeviceDialog(false);
              }}
              color="primary"
              // autoFocus
            >
              Go to Pricing Page
            </Button>
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
                setShowLimitExhausted(false);
                // if (testMode) setShowTestDeviceDialog(false);
              }}
              color="primary"
              // autoFocus
            >
              Dismiss
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Drawer
        open={Boolean(showReAnalyzeDrawer)}
        anchor="right"
        onClose={() => setShowReAnalyzeDrawer(null)}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={() => setShowReAnalyzeDrawer(null)}>
            <ChevronRight sx={{}} />
          </IconButton>
          <Typography color={'text.secondary'}>
            {
              analysisReviews?.find((ar) => ar?.id === showReAnalyzeDrawer)
                ?.title
            }
          </Typography>
        </Box>
        <Box
          p={2}
          sx={{
            width: '40vw',
            minHeight: 'calc(100vh - 58px)',
            maxHeight: 'calc(100vh - 58px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            // background: '#343a40',
            flex: 1,
          }}
        >
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            {salesReview?.transcript ? (
              <Box
                sx={{
                  borderRadius: 3,
                  border: '1px solid #e3e3e3',
                }}
              >
                <Box
                  sx={{
                    background: '#e3e3e3',
                    padding: 2,
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                  }}
                >
                  <Typography color={'text.secondary'} fontWeight={'bold'}>
                    Transcript
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    minHeight: '260px',
                    maxHeight: '260px',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                    },
                    whiteSpace: 'pre-wrap',
                    padding: 2,
                  }}
                >
                  {
                    analysisReviews?.find(
                      (ar) => ar?.id === showReAnalyzeDrawer,
                    )?.transcript
                  }
                </Typography>
              </Box>
            ) : null}
            {userInfo?.orgRoles?.includes('ORG_ADMIN') ? (
              <FormControl
                component="fieldset"
                variant="standard"
                // sx={{ marginTop: 2 }}
              >
                <FormLabel component="legend" sx={{ fontSize: '14px' }}>
                  Methodologies
                </FormLabel>
                <FormGroup row>
                  {orgConfigs?.selectedMethodologies?.map((mthd) => (
                    <FormControlLabel
                      key={mthd}
                      checked={methods?.includes(mthd)}
                      control={
                        <Checkbox size="small" value={mthd} name="methods" />
                      }
                      label={
                        mthd === 'meddpic' ? 'MEDDICC' : mthd.toUpperCase()
                      }
                      onChange={(e) => handleChange(e, false)}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            ) : (
              <>
                <Typography
                  variant={'body1'}
                  sx={{ marginTop: 1 }}
                  color={'text.secondary'}
                  // fontWeight={'bold'}
                >
                  Methodologies
                </Typography>
                <Typography variant={'body1'}>
                  {methods
                    ?.map((mthd) =>
                      mthd === 'meddpic' ? 'MEDDICC' : capitalizeText(mthd),
                    )
                    ?.join(', ')}
                </Typography>
              </>
            )}
            <Typography
              variant="body2"
              color={'text.secondary'}
              id="speakers-labels"
            >
              Speakers
            </Typography>
            {speakers &&
            Object.keys(speakers) &&
            Object.keys(speakers).length > 0
              ? Object.keys(speakers)
                  .sort()
                  .map((spk) => (
                    <Grid container spacing={1} key={spk}>
                      <Grid item xs={1}>
                        <Typography>{spk}</Typography>
                      </Grid>
                      <Grid item xs={11}>
                        <TextField
                          autoFocus
                          placeholder={`Name`}
                          variant="outlined"
                          color="primary"
                          size="small"
                          name={`${spk}_name`}
                          value={speakers?.[spk]}
                          onChange={(e) => {
                            const updatedSpeakes = {
                              ...speakers,
                              [spk]: e.target.value,
                            };
                            setSpeakers(updatedSpeakes);
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  ))
              : null}
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Who is the salesperson?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={speaker}
                onChange={(e) => setSpeaker(e.target.value)}
              >
                {speakers &&
                  Object.keys(speakers)
                    ?.sort()
                    ?.map((spk) => (
                      <FormControlLabel
                        key={spk}
                        value={spk}
                        control={<Radio />}
                        label={speakers?.[spk]}
                      />
                    ))}
              </RadioGroup>
            </FormControl>
            {/* {userInfo?.email === 'demo@qualification.ai' ? null : (
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Call Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={salesCallType}
                  onChange={(e) => setSalesCallType(e.target.value)}
                >
                  <FormControlLabel
                    value={'outbound'}
                    control={<Radio />}
                    label={'Outbound'}
                  />
                  <FormControlLabel
                    value={'inbound'}
                    control={<Radio />}
                    label={'Inbound'}
                  />
                </RadioGroup>
              </FormControl>
            )} */}
            {userInfo?.orgRoles?.includes('ORG_ADMIN') ? (
              <>
                <Typography variant="subtitle2" color={'text.secondary'}>
                  Select from available playbooks
                </Typography>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    labelId="playbook-select-label"
                    id="playbook-select"
                    name="selectedPlaybook"
                    MenuProps={{ disableScrollLock: true }}
                    onChange={(e) => setSelectedPlaybook(e.target.value)}
                    value={selectedPlaybook}
                  >
                    <MenuItem value={'select'}>
                      <Typography>Select from available playbooks</Typography>
                    </MenuItem>
                    {playbooks && playbooks?.length > 0
                      ? playbooks?.map((pb) => (
                          <MenuItem key={pb?.id} value={pb?.id}>
                            <Box
                              sx={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Typography>{pb?.tags?.join(', ')} </Typography>
                              <Typography>
                                {moment(pb?.createdOn).format('DD MMM YYYY')}
                                {pb?.selected ? '(Default)' : ''}
                              </Typography>
                            </Box>
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </>
            ) : null}
            {(orgConfigs?.showPlaybookToManagersOnly &&
              (userInfo?.orgRoles?.includes('ORG_ADMIN') ||
                userInfo?.orgRoles?.includes('WL_ORG_ADMIN'))) ||
            !orgConfigs?.showPlaybookToManagersOnly ? (
              <Box
                sx={{
                  borderRadius: 3,
                  border: '1px solid #e3e3e3',
                }}
              >
                <Box
                  sx={{
                    background: '#e3e3e3',
                    padding: 2,
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                  }}
                >
                  <Typography color={'text.secondary'} fontWeight={'bold'}>
                    Current Playbook
                  </Typography>
                </Box>
                <Box
                  sx={{
                    // minHeight: '260px',
                    maxHeight: '260px',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                    },
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      whiteSpace: 'pre-wrap',
                      flex: 1,
                      // borderRight: '1px solid #d3d3d3',
                      p: 2,
                    }}
                  >
                    {parse(playbook)}
                  </Typography>
                </Box>
              </Box>
            ) : null}
            <Box
              display={'flex'}
              justifyContent={'flex-end'}
              alignItems={'center'}
              p={2}
              width={1}
              borderTop={'1px solid #f1f1f1'}
            >
              <Button
                variant="contained"
                size="small"
                disabled={
                  analysisInProgress.includes(showReAnalyzeDrawer)
                    ? true
                    : false
                }
                onClick={() => {
                  if (
                    userDetails?.plan?.trailEndsOn > new Date().getTime() ||
                    userDetails?.plan?.currentPaymentStatus === 'paid'
                  ) {
                    if (
                      salesReview?.transcript ||
                      salesReview?.status === 'created'
                    ) {
                      handleAnalyze(
                        showReAnalyzeDrawer,
                        'list',
                        analysisReviews?.find(
                          (ar) => ar?.id === showReAnalyzeDrawer,
                        ),
                      );
                    } else {
                      return;
                    }
                  } else {
                    dispatch({
                      type: 'UPDATE_UI_STATE',
                      payload: {
                        key: 'showPlanExpired',
                        value: true,
                      },
                    });
                  }
                }}
              >
                Analyze
              </Button>
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Drawer
        open={Boolean(showAskDonna)}
        anchor="right"
        onClose={() => setShowAskDonna(null)}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={() => setShowAskDonna(null)}>
            <ChevronRight sx={{}} />
          </IconButton>
          <Typography color={'text.secondary'}>
            {showAskDonna?.title}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '40vw',
            minHeight: 'calc(100vh - 58px)',
            maxHeight: 'calc(100vh - 58px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            // background: '#343a40',
            flex: 1,
          }}
        >
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            {fetchingConversationById ? (
              <Box
                width={1}
                height={1}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                p={2}
              >
                <CircularProgress size={20} />
                <Typography variant="body1" color={'text.primary'} mt={2}>
                  Loading all chats ...
                </Typography>
              </Box>
            ) : conversationDetails ? (
              <>
                {conversationDetails?.presetMessageMap ||
                (conversationDetails?.messages &&
                  conversationDetails?.messages?.length > 0) ? (
                  <Box
                    flex={1}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                  >
                    <Box
                      ref={chatsScrollview}
                      sx={{
                        minHeight: 'calc(100vh - 115px)',
                        maxHeight: 'calc(100vh - 115px)',
                        overflowY: 'scroll',
                        '&::-webkit-scrollbar': {
                          width: '5px',
                        },
                        '&::-webkit-scrollbar-track': {
                          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: 2,
                        },
                        padding: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                      }}
                    >
                      {Object.values(
                        conversationDetails?.presetMessageMap || {},
                      )?.map(({ question, answer }, index) => (
                        <>
                          {question ? (
                            <Box
                              key={question?.id}
                              sx={{
                                borderTopLeftRadius:
                                  question?.gptMessage?.role === 'user'
                                    ? 16
                                    : 0,
                                borderTopRightRadius:
                                  question?.gptMessage?.role === 'user'
                                    ? 0
                                    : 16,
                                borderBottomLeftRadius:
                                  question?.gptMessage?.role === 'user'
                                    ? 8
                                    : 16,
                                borderBottomRightRadius:
                                  question?.gptMessage?.role === 'user'
                                    ? 16
                                    : 8,
                                maxWidth: '80%',
                                alignSelf:
                                  question?.gptMessage?.role === 'user'
                                    ? 'end'
                                    : 'start',
                                display: 'flex',
                                flexDirection: 'column',
                                // gap: 1,
                                boxShadow: theme.shadows[8],
                                background:
                                  question?.gptMessage?.role === 'user'
                                    ? theme.palette.primary.main
                                    : '#fff',
                              }}
                            >
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                sx={{
                                  padding: '8px 16px',
                                  gap: 4,
                                }}
                              >
                                {question?.gptMessage?.role === 'user' ? (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color:
                                        question?.gptMessage?.role === 'user'
                                          ? '#f1f1f1'
                                          : theme.palette.text.secondary,
                                    }}
                                  >
                                    System
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color:
                                        question?.gptMessage?.role === 'user'
                                          ? '#f1f1f1'
                                          : theme.palette.text.secondary,
                                    }}
                                  >
                                    Donna
                                  </Typography>
                                )}
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color:
                                      question?.gptMessage?.role === 'user'
                                        ? '#f1f1f1'
                                        : theme.palette.text.secondary,
                                  }}
                                >
                                  {moment(question?.createdOn).format(
                                    'DD MMMM YYYY hh:mm:ss A',
                                  )}
                                </Typography>
                              </Box>
                              <Typography
                                sx={{
                                  whiteSpace: 'pre-wrap',
                                  color:
                                    question?.gptMessage?.role === 'user'
                                      ? '#fff'
                                      : theme.palette.text.primary,
                                  padding: '0px 16px 16px 16px',
                                }}
                              >
                                {question?.gptMessage?.content}
                              </Typography>
                            </Box>
                          ) : null}
                          {answer ? (
                            <Box
                              key={answer?.id}
                              sx={{
                                borderTopLeftRadius:
                                  answer?.gptMessage?.role === 'user' ? 16 : 0,
                                borderTopRightRadius:
                                  answer?.gptMessage?.role === 'user' ? 0 : 16,
                                borderBottomLeftRadius:
                                  answer?.gptMessage?.role === 'user' ? 8 : 16,
                                borderBottomRightRadius:
                                  answer?.gptMessage?.role === 'user' ? 16 : 8,
                                maxWidth: '80%',
                                alignSelf:
                                  answer?.gptMessage?.role === 'user'
                                    ? 'end'
                                    : 'start',
                                display: 'flex',
                                flexDirection: 'column',
                                // gap: 1,
                                boxShadow: theme.shadows[8],
                                background:
                                  answer?.gptMessage?.role === 'user'
                                    ? theme.palette.primary.main
                                    : '#fff',
                              }}
                            >
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                sx={{
                                  padding: '8px 16px',
                                  gap: 4,
                                }}
                              >
                                {answer?.gptMessage?.role === 'user' ? (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color:
                                        answer?.gptMessage?.role === 'user'
                                          ? '#f1f1f1'
                                          : theme.palette.text.secondary,
                                    }}
                                  >
                                    You
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color:
                                        answer?.gptMessage?.role === 'user'
                                          ? '#f1f1f1'
                                          : theme.palette.text.secondary,
                                    }}
                                  >
                                    Donna
                                  </Typography>
                                )}
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color:
                                      answer?.gptMessage?.role === 'user'
                                        ? '#f1f1f1'
                                        : theme.palette.text.secondary,
                                  }}
                                >
                                  {moment(answer?.createdOn).format(
                                    'DD MMMM YYYY hh:mm:ss A',
                                  )}
                                </Typography>
                              </Box>
                              <Typography
                                sx={{
                                  whiteSpace: 'pre-wrap',
                                  color:
                                    answer?.gptMessage?.role === 'user'
                                      ? '#fff'
                                      : theme.palette.text.primary,
                                  padding: '0px 16px 16px 16px',
                                }}
                              >
                                {answer?.gptMessage?.content}
                              </Typography>
                            </Box>
                          ) : (
                            <Box
                              width={1}
                              height={1}
                              display={'flex'}
                              justifyContent={'center'}
                              alignItems={'center'}
                              flexDirection={'column'}
                              p={2}
                              sx={{
                                borderTopLeftRadius:
                                  answer?.gptMessage?.role === 'user' ? 16 : 0,
                                borderTopRightRadius:
                                  answer?.gptMessage?.role === 'user' ? 0 : 16,
                                borderBottomLeftRadius:
                                  answer?.gptMessage?.role === 'user' ? 8 : 16,
                                borderBottomRightRadius:
                                  answer?.gptMessage?.role === 'user' ? 16 : 8,
                                maxWidth: '80%',
                                alignSelf:
                                  answer?.gptMessage?.role === 'user'
                                    ? 'end'
                                    : 'start',
                                display: 'flex',
                                flexDirection: 'column',
                                // gap: 1,
                                boxShadow: theme.shadows[8],
                                background:
                                  answer?.gptMessage?.role === 'user'
                                    ? theme.palette.primary.main
                                    : '#fff',
                              }}
                            >
                              <CircularProgress size={20} />
                              <Typography
                                variant="body1"
                                color={'text.primary'}
                                mt={4}
                              >
                                This will take a few seconds. Please check back
                                after a short while.
                              </Typography>
                            </Box>
                          )}
                        </>
                      ))}
                      {conversationDetails?.messages?.map((msg, index) => (
                        <>
                          {msg ? (
                            <Box
                              key={msg?.id}
                              sx={{
                                borderTopLeftRadius:
                                  msg?.gptMessage?.role === 'user' ? 16 : 0,
                                borderTopRightRadius:
                                  msg?.gptMessage?.role === 'user' ? 0 : 16,
                                borderBottomLeftRadius:
                                  msg?.gptMessage?.role === 'user' ? 8 : 16,
                                borderBottomRightRadius:
                                  msg?.gptMessage?.role === 'user' ? 16 : 8,
                                maxWidth: '80%',
                                alignSelf:
                                  msg?.gptMessage?.role === 'user'
                                    ? 'end'
                                    : 'start',
                                display: 'flex',
                                flexDirection: 'column',
                                // gap: 1,
                                boxShadow: theme.shadows[8],
                                background:
                                  msg?.gptMessage?.role === 'user'
                                    ? theme.palette.primary.main
                                    : '#fff',
                              }}
                            >
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                sx={{
                                  padding: '8px 16px',
                                  gap: 4,
                                }}
                              >
                                {msg?.gptMessage?.role === 'user' ? (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color:
                                        msg?.gptMessage?.role === 'user'
                                          ? '#f1f1f1'
                                          : theme.palette.text.secondary,
                                    }}
                                  >
                                    {`${msg?.customFields?.userDetails?.firstName} ${msg?.customFields?.userDetails?.lastName}` !==
                                    userInfo?.fullName
                                      ? `${msg?.customFields?.userDetails?.firstName} ${msg?.customFields?.userDetails?.lastName}`
                                      : 'You'}
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color:
                                        msg?.gptMessage?.role === 'user'
                                          ? '#f1f1f1'
                                          : theme.palette.text.secondary,
                                    }}
                                  >
                                    Donna
                                  </Typography>
                                )}
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color:
                                      msg?.gptMessage?.role === 'user'
                                        ? '#f1f1f1'
                                        : theme.palette.text.secondary,
                                  }}
                                >
                                  {moment(msg?.createdOn).format(
                                    'DD MMMM YYYY hh:mm:ss A',
                                  )}
                                </Typography>
                              </Box>
                              <Typography
                                sx={{
                                  whiteSpace: 'pre-wrap',
                                  color:
                                    msg?.gptMessage?.role === 'user'
                                      ? '#fff'
                                      : theme.palette.text.primary,
                                  padding: '0px 16px 16px 16px',
                                }}
                              >
                                {msg?.gptMessage?.content}
                              </Typography>
                            </Box>
                          ) : null}
                        </>
                      ))}
                    </Box>
                  </Box>
                ) : !conversationDetails?.presetMessageMap &&
                  (!conversationDetails?.messages ||
                    (conversationDetails?.messages &&
                      conversationDetails?.messages?.length === 0)) ? (
                  <Box
                    width={1}
                    height={1}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    p={2}
                    flexDirection={'column'}
                  >
                    <Typography variant="body1" color={'text.secondary'} mt={4}>
                      No conversation done yet.
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    width={1}
                    height={1}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    p={2}
                    flexDirection={'column'}
                  >
                    <CircularProgress size={20} />
                    <Typography variant="body1" color={'text.primary'} mt={4}>
                      This will take a few seconds. Please check back after a
                      short while.
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <Box
                flex={3}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                gap={2}
              >
                <Typography variant="body1" color={'text.secondary'} mt={4}>
                  No conversation done yet.
                </Typography>
              </Box>
            )}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (
                  userDetails?.plan?.trailEndsOn > new Date().getTime() ||
                  userDetails?.plan?.currentPaymentStatus === 'paid'
                ) {
                  setGeneratingResponse(true);
                  dispatch(
                    getMessageResponse(
                      showAskDonna?.conversationId,
                      askAIQuestion,
                      showAskDonna?.speaker,
                      null,
                      null,
                      'v2',
                      wordsLimit,
                      async (data) => {
                        // console.log(data);
                        setGeneratingResponse(false);
                        setAskAIQuestion('');
                        dispatch({
                          type: 'UPDATE_CONVERSATION_MESSAGES',
                          payload: data,
                        });
                        if (chatsScrollview?.current) {
                          chatsScrollview?.current?.scroll({
                            top: chatsScrollview?.current?.scrollHeight,
                            behaviour: 'smooth',
                          });
                        }
                      },
                      (errorCode) => {
                        setGeneratingResponse(false);
                        if (errorCode === 'quota exceeded') {
                          // setShowLimitExhausted(errorCode);
                        }
                      },
                    ),
                  );
                } else {
                  dispatch({
                    type: 'UPDATE_UI_STATE',
                    payload: {
                      key: 'showPlanExpired',
                      value: true,
                    },
                  });
                }
              }}
            >
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                gap={1}
                borderTop={'1px solid #d3d3d3'}
                p={1}
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  zIndex: 11,
                  width: 1,
                  background: '#fff',
                }}
              >
                <TextField
                  autoFocus
                  placeholder="Ask any question"
                  variant="outlined"
                  color="primary"
                  size="small"
                  name="askAIQuestion"
                  value={askAIQuestion}
                  onChange={(e) => setAskAIQuestion(e.target.value)}
                  sx={{ flex: 4 }}
                  autoComplete="off"
                />
                <FormControl size="small" sx={{ minWidth: 150 }}>
                  <InputLabel id="wordsLimit">Words Limit</InputLabel>
                  <Select
                    variant="outlined"
                    size="small"
                    labelId={'wordsLimit'}
                    label={'Words Limit'}
                    id={'wordsLimit'}
                    name={'wordsLimit'}
                    MenuProps={{ disableScrollLock: true }}
                    onChange={(e) => {
                      setWordsLimit(e.target.value);
                    }}
                    value={wordsLimit}
                  >
                    <MenuItem value={50}>
                      <Typography variant="subtitle2">50</Typography>
                    </MenuItem>
                    <MenuItem value={100}>
                      <Typography variant="subtitle2">100</Typography>
                    </MenuItem>
                    <MenuItem value={150}>
                      <Typography variant="subtitle2">150</Typography>
                    </MenuItem>
                    <MenuItem value={200}>
                      <Typography variant="subtitle2">200</Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
                <Button
                  sx={{ flex: 1 }}
                  variant="contained"
                  size="small"
                  color="primary"
                  disabled={
                    generatingResponse || !askAIQuestion.trim()
                    // conversationDetails?.presetQuestionStatus !== 'completed'
                  }
                  startIcon={
                    generatingResponse ? (
                      <CircularProgress size={20} />
                    ) : (
                      <AutoAwesome />
                    )
                  }
                  type="submit"
                >
                  Ask Donna
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Drawer>
      <Drawer
        open={Boolean(showTranscriptionFailed)}
        anchor="right"
        onClose={() => setShowTranscriptionFailed(null)}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={() => setShowTranscriptionFailed(null)}>
            <ChevronRight sx={{}} />
          </IconButton>
          <Typography color={'text.secondary'}>
            {showTranscriptionFailed?.title}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '40vw',
            minHeight: 'calc(100vh - 58px)',
            maxHeight: 'calc(100vh - 58px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            // background: '#343a40',
            flex: 1,
          }}
        >
          <Box
            gap={2}
            display={'flex'}
            flexDirection={'column'}
            alignItems={
              transcriptReady && salesReview
                ? 'stretch'
                : transcriptionFailed
                ? 'center'
                : saleReviewId
                ? 'center'
                : 'flex-start'
            }
            justifyContent={
              transcriptReady && salesReview
                ? 'flex-start'
                : transcriptionFailed
                ? 'center'
                : saleReviewId
                ? 'center'
                : 'flex-start'
            }
            sx={{ padding: 2 }}
          >
            <Typography
              variant="body1"
              color={'text.secondary'}
              textAlign={'center'}
            >
              Transcript is not available. Press analyse to start transcribing
              and analysing call.
            </Typography>
            {transcriptReady && salesReview ? (
              <Box display={'flex'} flexDirection={'column'} gap={2}>
                <Box
                  sx={{
                    borderRadius: 3,
                    border: '1px solid #e3e3e3',
                  }}
                >
                  <Box sx={{ background: '#e3e3e3', padding: 2 }}>
                    <Typography color={'text.secondary'} fontWeight={'bold'}>
                      Transcript
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      minHeight: '260px',
                      maxHeight: '260px',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                      },
                      whiteSpace: 'pre-wrap',
                      padding: 2,
                    }}
                  >
                    {salesReview?.transcript}
                  </Typography>
                </Box>
                {/* {orgConfigs?.multiLanguageSupport ? (
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend" sx={{ fontSize: '14px' }}>
                      Languages
                    </FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        checked={languages?.includes('english')}
                        control={
                          <Checkbox
                            size="small"
                            value={'english'}
                            name="languages"
                          />
                        }
                        label={'English'}
                        onChange={(e) => handleLanguageChange(e)}
                      />
                      <FormControlLabel
                        checked={languages?.includes('hindi')}
                        control={
                          <Checkbox
                            size="small"
                            value={'hindi'}
                            name="languages"
                          />
                        }
                        label={'Hindi'}
                        onChange={(e) => handleLanguageChange(e)}
                      />
                    </FormGroup>
                  </FormControl>
                ) : null} */}
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Who is the salesperson?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={speaker}
                    onChange={(e) => setSpeaker(e.target.value)}
                  >
                    {speakers &&
                      Object.keys(speakers)
                        ?.sort()
                        ?.map((spk) => (
                          <FormControlLabel
                            key={spk}
                            value={spk}
                            control={<Radio />}
                            label={speakers?.[spk]}
                          />
                        ))}
                  </RadioGroup>
                </FormControl>
                <FormLabel id="speakers-labels">Speakers</FormLabel>
                {speakers &&
                Object.keys(speakers) &&
                Object.keys(speakers).length > 0
                  ? Object.keys(speakers)
                      .sort()
                      .map((spk) => (
                        <Grid container spacing={1} key={spk}>
                          <Grid item xs={1}>
                            <Typography flex={1}>{spk}</Typography>
                          </Grid>
                          <Grid item xs={11}>
                            <TextField
                              flex={2}
                              autoFocus
                              placeholder={`Name`}
                              variant="outlined"
                              color="primary"
                              size="small"
                              name={`${spk}_name`}
                              value={speakers?.[spk]}
                              onChange={(e) => {
                                const updatedSpeakes = {
                                  ...speakers,
                                  [spk]: e.target.value,
                                };
                                setSpeakers(updatedSpeakes);
                              }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      ))
                  : null}
                <Box
                  sx={{
                    borderRadius: 3,
                    border: '1px solid #e3e3e3',
                  }}
                >
                  <Box
                    sx={{
                      background: '#e3e3e3',
                      padding: 2,
                      borderTopLeftRadius: 3,
                      borderTopRightRadius: 3,
                    }}
                  >
                    <Typography color={'text.secondary'} fontWeight={'bold'}>
                      Playbook
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      // minHeight: '260px',
                      maxHeight: '260px',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                      },
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        whiteSpace: 'pre-wrap',
                        flex: 1,
                        // borderRight: '1px solid #d3d3d3',
                        p: 2,
                      }}
                    >
                      {parse(playbook)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ) : transcriptionFailed ? (
              <Box
                sx={{
                  border: '1px solid #f1f1f1',
                  borderRadius: 2,
                  display: 'flex',
                  padding: '32px 16px',
                  flexDirection: 'column',
                  gap: 2,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body1">
                  Failed to generate transcript. Please try uploading the
                  recording again.
                </Typography>
              </Box>
            ) : saleReviewId ? (
              <Box
                sx={{
                  border: '1px solid #f1f1f1',
                  borderRadius: 2,
                  display: 'flex',
                  padding: '32px 16px',
                  flexDirection: 'column',
                  gap: 2,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress
                  size={30}
                  sx={{ color: theme.palette.primary.main }}
                />
                <Typography variant="body1">
                  Please wait while we generate the transcript ...
                </Typography>
              </Box>
            ) : (
              <>
                {orgConfigs?.languagesForAnalysis?.length > 1 &&
                userDetails?.userSetting?.languagesForAnalysis?.length > 1 ? (
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend" sx={{ fontSize: '14px' }}>
                      Pick Languages
                    </FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        checked={languages?.includes('english')}
                        control={
                          <Checkbox
                            size="small"
                            value={'english'}
                            name="languages"
                          />
                        }
                        label={'English'}
                        onChange={(e) => handleLanguageChange(e)}
                      />
                      <FormControlLabel
                        checked={languages?.includes('hindi')}
                        control={
                          <Checkbox
                            size="small"
                            value={'hindi'}
                            name="languages"
                          />
                        }
                        label={'Hindi'}
                        onChange={(e) => handleLanguageChange(e)}
                      />
                    </FormGroup>
                  </FormControl>
                ) : null}
              </>
            )}
          </Box>
          <Box
            style={{
              marginTop: 20,
              display: 'flex',
              justifyContent: 'space-between',
              border: '1px solid #d3d3d3',
              padding: '8px 16px',
            }}
          >
            <Button
              size="small"
              color="primary"
              onClick={() => {
                setShowTranscriptionFailed(null);
              }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              color="primary"
              variant="contained"
              disabled={
                retriggeringCall ||
                analysisInProgress.includes(showTranscriptionFailed?.id)
              }
              onClick={(e) => {
                if (
                  transcriptReady ||
                  (showTranscriptionFailed?.status !== 'failed' &&
                    showTranscriptionFailed?.status !== 'created')
                ) {
                  handleAnalyze(showTranscriptionFailed?.id, 'list');
                }
                if (
                  showTranscriptionFailed?.status === 'failed' ||
                  showTranscriptionFailed?.status === 'created'
                ) {
                  handleReAnalyze(
                    showTranscriptionFailed?.mediaId,
                    showTranscriptionFailed,
                  );
                } else return;
              }}
            >
              {showTranscriptionFailed?.status === 'failed'
                ? 'Confirm and Re-Trigger Transcription'
                : showTranscriptionFailed?.status === 'created'
                ? 'Start Transcription'
                : 'Analyze'}
            </Button>
          </Box>
        </Box>
      </Drawer>
      <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this sales call?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setShowConfirmDeleteDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              dispatch(
                updateSalesReviewData(
                  showConfirmDeleteDialog,
                  {
                    hidden: true,
                  },
                  (data) => {
                    setShowConfirmDeleteDialog(false);
                    dispatch(
                      getSalesCallReviews(
                        {
                          accountTagId:
                            activeAccount !== activeUUID ? activeAccount : null,
                          userId: uId || null,
                          // keywords: '',
                          lastSeen: null,
                          lastTime: null,
                          size: 10,
                          teamId: currentTeam?.id || teamId || null,
                        },
                        (data) => {
                          setSearchKeywords([]);
                        },
                      ),
                    );
                  },
                  () => {
                    setShowConfirmDeleteDialog(false);
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={Boolean(showSyncHubspotPropertiesDialog)}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
            setShowSyncHubspotPropertiesDialog(null);
          else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            // maxHeight: '30vh',
            maxWidth: '70vw',
            minWidth: '50vw',
            maxHeight: '90vh',
            overflowY: 'scroll',
            outline: 'none',
            borderRadius: 1,
            // padding: 3,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              padding: 2,
            }}
          >
            <Typography variant="body1">Hubspot Properties Sync</Typography>
            <Typography variant="subtitle2" color={'text.secondary'}>
              Select the deal you want to update the hubspot properties for
            </Typography>
            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel id="deal-select">Select Deal</InputLabel>
              <Select
                size="small"
                labelId="deal-select-label"
                id="deal-select"
                name="selectedDeal"
                label="Select Deal"
                MenuProps={{ disableScrollLock: true }}
                onChange={(e) => setSelectedDeal(e.target.value)}
                value={selectedDeal}
              >
                {hubspotDeals?.map((deal) => (
                  <MenuItem value={deal?.properties?.dealname} key={deal?.id}>
                    <Typography variant="caption">
                      {deal?.properties?.dealname}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="body1" color={'textSecondary'}>
              Sync Properties
            </Typography>
            {Object.keys(
              hubspotDeals?.find(
                (deal) => deal.properties?.dealname === selectedDeal,
              )?.properties || {},
            )
              ?.filter(
                (property) =>
                  orgConfigs?.hubspotDealProperties
                    ?.map((prop) => prop?.name)
                    ?.includes(property) &&
                  property !== 'dealname' &&
                  property !== 'createdate',
              )
              ?.map((property, index) => (
                <Box key={property} sx={{ display: 'flex', gap: 1 }}>
                  <Typography>
                    {index + 1}.{' '}
                    {orgConfigs?.hubspotDealProperties?.find(
                      (pr) => pr?.name === property,
                    )?.label || property}
                  </Typography>
                  {orgConfigs?.hubspotDealProperties?.find(
                    (pr) => pr?.name === property,
                  )?.type === 'datetime' ? (
                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          // label="Meeting Time"
                          value={dayjs(
                            hubspotDeals?.find(
                              (deal) =>
                                deal.properties?.dealname === selectedDeal,
                            )?.properties?.[property] || null,
                          )}
                          // onChange={(newValue) => {
                          //   console.log(
                          //     // new Date(
                          //     hubspotDeals?.find(
                          //       (deal) =>
                          //         deal.properties?.dealname === selectedDeal,
                          //     )?.properties?.[property],
                          //     // ),
                          //   );
                          //   console.log(newValue);
                          // }}
                          disabled
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          // label="Meeting Time"
                          value={dayjs(
                            hubspotDeals?.find(
                              (deal) =>
                                deal.properties?.dealname === selectedDeal,
                            )?.properties?.[property],
                          )}
                          onChange={(newValue) => {
                            console.log(
                              // new Date(
                              hubspotDeals?.find(
                                (deal) =>
                                  deal.properties?.dealname === selectedDeal,
                              )?.properties?.[property],
                              // ),
                            );
                            console.log(newValue);
                            setHubspotDeals((prev) => {
                              const copy = [...prev];
                              copy[
                                prev.findIndex(
                                  (deal) =>
                                    deal?.properties?.dealname === selectedDeal,
                                )
                              ].properties[property] = newValue;
                              // console.log(copy);
                              return copy;
                            });
                          }}
                          // disabled
                        />
                      </LocalizationProvider>
                    </>
                  ) : (
                    <>
                      <TextField
                        placeholder={`Current Value`}
                        variant="outlined"
                        label={'Current Value'}
                        color="primary"
                        size="small"
                        name={`currentValue-${property}`}
                        defaultValue={
                          hubspotDeals?.find(
                            (deal) =>
                              deal.properties?.dealname === selectedDeal,
                          )?.properties?.[property]
                        }
                        disabled
                      />
                      <TextField
                        placeholder={`New Value`}
                        variant="outlined"
                        label={'New Value'}
                        color="primary"
                        size="small"
                        name={`newValue-${property}`}
                        value={
                          hubspotDeals?.find(
                            (deal) =>
                              deal.properties?.dealname === selectedDeal,
                          )?.properties?.[property]
                        }
                        onChange={(e) => {
                          setHubspotDeals((prev) => {
                            const copy = [...prev];
                            copy[
                              prev.findIndex(
                                (deal) =>
                                  deal?.properties?.dealname === selectedDeal,
                              )
                            ].properties[property] = e.target.value;
                            // console.log(copy);
                            return copy;
                          });
                        }}
                      />
                    </>
                  )}
                </Box>
              ))}
          </Box>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            p={'8px 16px'}
          >
            <Button
              size="small"
              variant="outlined"
              onClick={(e) => {
                setShowSyncHubspotPropertiesDialog(null);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              // disabled={updatingHubspotProperties}
              onClick={(e) => {
                console.log(hubspotDeals);
                dispatch(
                  updateHubspotDeal(
                    hubspotDeals?.find(
                      (deal) => deal.properties?.dealname === selectedDeal,
                    )?.id,
                    hubspotDeals?.find(
                      (deal) => deal.properties?.dealname === selectedDeal,
                    )?.properties,
                    () => {
                      setShowSyncHubspotPropertiesDialog(null);
                      dispatch(
                        showAlert({
                          message: 'Hubspot properties synced successfully',
                          showCross: true,
                          title: null,
                          type: 'success',
                          autoHideDuration: 5000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    },
                    () => {},
                  ),
                );
              }}
              color="primary"
              // autoFocus
            >
              Sync to hubspot
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

VideoPreview.propTypes = {
  selectedAudio: PropTypes.object,
  onDurationChange: PropTypes.func,
};

SalesCallAnalysis.propTypes = {
  uId: PropTypes.string,
  member: PropTypes.object,
  parentComponent: PropTypes.string,
  currentTeam: PropTypes.object,
};

export default SalesCallAnalysis;
